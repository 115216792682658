import store from "@store/Store.js";

const errorHandle = (error, isCallCenter = false) => {
    const tempMessage = {
        title: '',
        messages: [],
        callCenterMessage: '',
        modalType: 'error'
    }

    if (error.response){
        tempMessage.title = error.response?.data?.message
        tempMessage.messages = error.response?.data?.errors ? [...Object.values(error.response.data.errors).map(error => error.join(' '))] : [error.response.data.error]
    }
    else{
        tempMessage.messages = [
            error.request ? 'İstek gönderildi ancak herhangi bir yanıt alınmadı.' : 'Beklenmedik bir hata oluştu.'
        ]
    }

    if (isCallCenter) {
        tempMessage.callCenterMessage = true
    }

    console.log("Hata bilgileri: ",error)

    store.commit('addApiErrorValue', tempMessage)
}

const handleErrorResponse = (response) => {
    if (response?.data?.error)
        return {
            title: response.data.message,
            messages: [response.data.error]
        };
    else if (response?.data?.errors)
        return {
            title: response.data.message,
            messages: [...Object.values(response.data.errors)]
        };
}

const initialErrorsHandle = (errors) => {
    store.commit('setApiErrorValue', [{
        title: 'Hata',
        messages: errors && !Array.isArray(errors) ? Object.values(errors) : 'Beklenmedik bir hata oluştu.',
        callCenterMessage: '',
        modalType: 'error'
    }])
}

const listPageGetApiErrorHandle = (error) => {
    if (error.response) return [error.response, '']
    else if (error.request) return [{}, 'İstek gönderildi ancak herhangi bir yanıt alınmadı. Lütfen tekrar arama yapınız.']
    else return [{} ,'Beklenmedik bir hata oluştu. Lütfen tekrar arama yapınız.']
}

export default {
    errorHandle,
    initialErrorsHandle,
    listPageGetApiErrorHandle
}
