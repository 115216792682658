import helpers from "@helpers/Index.js";
import store from "@store/Store.js";

const setUrlAddressWithBusId = (selectedBusId) => {
    const currentUrl = window.location.href
    const currentUrlWithoutSelectedBusId = currentUrl.split('&selectedBusId=')[0]
    let newUrl = selectedBusId
        ? `${currentUrlWithoutSelectedBusId}&selectedBusId=${selectedBusId}`
        : currentUrlWithoutSelectedBusId

    if (newUrl !== currentUrl){
        window.history.pushState(window.history.state, null, newUrl)
        if (helpers.isIosChromeAndEdgeBrowser()){
            window.history.replaceState(window.history.state, null, newUrl)
        }
    }
}

const handlePassengers = (payload) => {
    if (payload.isPayment){
        payload.seats = payload.seats.map((seat) => {
            return{
                ...seat,
                number: seat.bus_ticket.seat_number
            }
        })
    }

    return handleReservationPagePassengers(payload.seats, !!payload.isPayment)
}

const getPassengersForSend = (passengers, isLogin) => {
    const returnedValues = []

    passengers.forEach(passenger => {
        const passengerInfo = passenger.passengerInformation
        const tempPassenger = {
            seat_number: passenger.number,
            name: passengerInfo.nameProps.value,
            surname: passengerInfo.lastNameProps.value,
            gender: passenger.gender,
            citizen_id: passengerInfo.identityNumberProps.value,
            is_foreign: false
        }

        if (passengerInfo.identityNumberProps.isNotTurkishPeople){
            tempPassenger.is_foreign = true
        }

        const isAgent = store.getters._getAgentCode
        if (isLogin && passengerInfo.saveProps && !isAgent){
            if (passengerInfo.saveProps.isSave && passengerInfo.saveProps.isSave.value){
                tempPassenger.add_to_passenger_list = passengerInfo.saveProps.isSave.value
            }
            else if(passengerInfo.saveProps.isUpdate && passengerInfo.saveProps.isUpdate.value){
                tempPassenger.update_from_passenger_list = passengerInfo.saveProps.isUpdate.value
                tempPassenger.ref_id = passengerInfo.saveProps.isUpdate.ref_id
            }
        }

        returnedValues.push(tempPassenger)
    })

    return returnedValues
}

const handleReservationPagePassengers = (seats, isPayment = false) => {
    const newPassengerFormValues = []

    seats.forEach((seat) => {
        const { gender, number } = seat
        const tempPassengerForm = createEmptyPassengerFormValues(isPayment, seat)
        tempPassengerForm.gender = gender
        tempPassengerForm.number = number
        tempPassengerForm.title = `${number}. Koltuk yolcu bilgisi (${gender === 'male' ? 'Erkek' : 'Kadın' })`
        newPassengerFormValues.push(tempPassengerForm)
    })

    return newPassengerFormValues
}

const createEmptyPassengerFormValues = (isPayment, seat) => {
    const passengerFormValues = {
        gender: '',
        title: '',
        number: 0,
        passengerInformation: {
            nameProps: {
                placeholder: 'Ad',
                placeholderType: 'top',
                errorMessage: '',
                result: seat.name ? true : null,
                value: seat.name ?? '',
            },
            lastNameProps: {
                placeholder: 'Soyad',
                placeholderType: 'top',
                errorMessage: '',
                result: seat.surname ? true : null,
                value: seat.surname ?? '',
            },
            identityNumberProps: {
                placeholder: 'TC Kimlik No',
                isNotTurkishPeople: seat.name ? !seat.citizen_id : false,
                placeholderType: 'top',
                errorMessage: '',
                result: seat.surname ? true : null,
                value: seat.citizen_id ?? '',
            },
            saveProps: {
                isSave: {
                    value: true
                },
            }
        }
    }
    if (isPayment){
        delete passengerFormValues.passengerInformation.saveProps
    }
    return passengerFormValues
}


export default{
    handlePassengers,
    getPassengersForSend,
    setUrlAddressWithBusId,
}
