import axios from "axios";
import helpers from "@helpers/Index.js";

const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'accept': 'application/json',
}

const Api = axios.create({
    baseURL: helpers.routeHandler('') + "/api",
    headers: headers,
})

const Auth = axios.create({
    baseURL: helpers.routeHandler('') + "/uyelik",
    headers: headers,
})

export default {
    Api,
    Auth,
}
