import GlobalAxios from "@utils/Api/GlobalAxios.js";

const tripPay = (url, sendData) => {
    return GlobalAxios.Api.post(
        url,
        sendData
    )
}

export default {tripPay}
