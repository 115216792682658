import GlobalAxios from "@utils/Api/GlobalAxios.js";

const contact = (sendData) => {
    return GlobalAxios.Api.post(
        '/contact',
        sendData
    )
}

export default {contact}
