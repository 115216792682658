

const FlightPackages = {
    state: {
        packagesShowedFlightId: null,
        selectedDeparturePackageId: null,
        selectedReturnPackageId: null,
    },

    mutations: {
        setPackagesShowedFlightId(state, flightId){
            state.packagesShowedFlightId = flightId
        },

        setSelectedDeparturePackageId(state, packageId){
            state.selectedDeparturePackageId = packageId
        },

        setSelectedReturnPackageId(state, packageId){
            state.selectedReturnPackageId = packageId
        }
    },

    getters: {
        _getPackagesShowedFlightId: state => state.packagesShowedFlightId,

        _getSelectedDeparturePackageId: state => state.selectedDeparturePackageId,

        _getSelectedReturnPackageId: state => state.selectedReturnPackageId,
    },
}

export default FlightPackages;
