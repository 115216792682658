import store from "@store/Store.js";
import helpers from "@helpers/Index.js";
import createSearchFormValuesAndCalculatePassengerCount from "@helpers/ListPages/Flight.js";

const createSearchFormValuesWithSearchData = (pageType) => {
    const newSearchForm = createNewSearchForm(pageType)

    if (pageType === 'flight') return newSearchForm

    store.commit('addBeforeSearch', {value: newSearchForm, type: pageType})
    store.commit('setTicketTabsSearchForm', {searchForm: newSearchForm, pageType})
}

const createNewSearchForm = (pageType) => {
    switch (pageType) {
        case 'bus':
            return createBusSearchForm()
        case 'hotel':
            return createHotelSearchForm()
        case 'car':
            return createCarSearchForm()
        case 'flight':
            return createSearchFormValuesAndCalculatePassengerCount()
    }
}

const createBusSearchForm = () => {
    const {origin, destination, departure} = {...store.getters._getPageProps.searchForm}
    return {
        locationValues: {
            departurePlace: origin,
            landingPlace: destination,
        },
        dateValues: {
            departureDate: helpers.convertStringDateToObjectDate(new Date(departure), 'departure')
        },
    }
}

const createHotelSearchForm = () => {
    const {location, start_date, end_date, rooms} = {...store.getters._getPageProps.searchForm}
    const handleHotelGuests = guests => guests.map(guest => ({
        adultValue: Number(guest.adults),
        childrenAges: [...(guest.children_ages || [])]
    }))

    return {
        locationValues: {
            departurePlace: location,
        },
        dateValues: {
            departureDate: helpers.convertStringDateToObjectDate(new Date(start_date), 'departure'),
            landingDate: helpers.convertStringDateToObjectDate(new Date(end_date), 'landing')
        },
        guestSize: handleHotelGuests(rooms),
    }
}

const createCarSearchForm = () => {
    const {pickup_location, drop_off_location, pickup_datetime, drop_off_datetime} = {...store.getters._getPageProps.searchForm}

    const handleCarTimes = (time, type) =>{
        return  {
            value: time,
            type: type,
            isDisable: false,
            valueTypeNumber: (Number(time.split(':')[0]) * 60) + Number(time.split(':')[1])
        }
    }

    return {
        locationValues: {
            departurePlace: pickup_location,
            landingPlace: drop_off_location,
        },
        dateValues: {
            departureDate: helpers.convertStringDateToObjectDate(new Date(pickup_datetime.split('T')[0]), 'departure'),
            landingDate: helpers.convertStringDateToObjectDate(new Date(drop_off_datetime.split('T')[0]), 'landing')
        },
        isOtherLocation: JSON.stringify(pickup_location) !== JSON.stringify(drop_off_location),
        timeValues: {
            delivery: handleCarTimes(drop_off_datetime.split('T')[1],'delivery'),
            pickup: handleCarTimes(pickup_datetime.split('T')[1],'pickup'),
        },
    }
}

export default createSearchFormValuesWithSearchData;
