import GlobalAxios from "@utils/Api/GlobalAxios.js";
import {router} from "@inertiajs/vue3";
import helpers from "@helpers/Index.js";

const pnrQuery = (sendData) => {
    GlobalAxios.Api.post(
        '/pnr-query',
        sendData
    )
        .then((res) => {
            router.visit(res.data.data.view_link)
        })
        .catch((error)=>{
            helpers.apiErrorHanding.errorHandle(error)
            return false
        })
        .finally(()=>{

        })
}

export default {pnrQuery}
