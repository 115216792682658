const PriceAlerts = {
    state: {
        priceAlerts: [],
    },

    mutations: {
        addPriceAlerts(state,priceAlert){
            state.priceAlerts.push(priceAlert)
        },

        deletePriceAlertList(state,index){
            state.priceAlerts.splice(index,1)
        },
    },

    getters: {
        _getPriceAlerts: state => state.priceAlerts,
    },
}

export default PriceAlerts
