import apiErrorHanding from "@helpers/ApiErrorHanding.js";
import localStorageFunctions from "@helpers/LocalStorage/Index.js"
import dateFormat from "@helpers/DateFormat.js";
import {getDate, getDay, getMonth, getYear} from "date-fns";
import {Ziggy} from "../../ziggy.js";

const changeBodyOverflowY = (isToggle) => {
    if (typeof document !== "undefined") {
        document.querySelector('body').style.overflowY = isToggle ? 'hidden' : null
    }
}

const convertStringDateToObjectDate = (date,type) => {
    const tempDate = new Date(typeof date === "undefined" || date.toString().length === 0 ? null : date)
    const monthLongNames = [ "Ocak","Şubat","Mart","Nisan","Mayıs","Haziran", "Temmuz","Ağustos","Eylül","Ekim","Kasım","Aralık" ]
    const dayLongNames = [ "Pazar","Pazartesi","Salı","Çarşamba","Perşembe","Cuma","Cumartesi" ]
    const dayShortNames = [ "Paz","Pzt","Sal","Çar","Per","Cum","Cmt" ]
    return  {
        dayNumber: getDate(tempDate).toString().padStart(2, '0'),
        dayLong: dayLongNames[getDay(tempDate)],
        dayShort: dayShortNames[tempDate.getDay()],
        monthNumber: (getMonth(tempDate) + 1).toString().padStart(2, '0'),
        monthLong: monthLongNames[getMonth(tempDate)],
        monthShort: monthLongNames[getMonth(tempDate)].substring(0,3),
        year: getYear(tempDate),
        type: type,
    }
}

const handleDurationTime = ({ day, hours, minutes }) => {
    return `${day ? day + 'gün ' : ''}${hours ? hours + 'sa ' : ''}${minutes ? minutes + 'dk' : ''}`.trim()
}

const priceFormat = (price) => {
    let [whole, fraction] = Number(price).toFixed(2).split('.');
    whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${whole},${fraction}`;
}

//const createCdnUrl = (url, { fit = 'contain', width = null, height = null, quality = 75 }) => {
//    if (import.meta.env.VITE_APP_ENV !== 'production') return url
//
//    let path = `https://cdn.sorgulamax.com/cdn-cgi/image/fit=${fit},quality=${quality}`;
//    if (width) path += `,width=${width}`;
//    if (height) path += `,height=${height}`;
//    return `${path}/${url}`;
//};

const routeHandler = (name, parameters = {}) => {
    try {
        let routeUri = Ziggy.routes[name].uri

        Object.entries(parameters).forEach(([key, value]) => routeUri = routeUri.replace(`{${key}}`, value))

        return routeUri.startsWith("\/") ? (Ziggy.url + routeUri) : `${Ziggy.url}/${routeUri}`
    }
    catch (e) {
        return Ziggy.url
    }
}

const getQueryParams = (data = null) => {
    try {
        if (typeof window === 'undefined') return {};
        const decodedString = decodeURIComponent((data || window.location.search).substring(1));
        const parsedObj = {};

        decodedString.split('&').forEach(pair => {
            const [key, value] = pair.split('=');
            const keys = key.split('[').map(k => k.replace(']', ''));

            let current = parsedObj;
            for (let i = 0; i < keys.length; i++) {
                const k = keys[i];
                if (i === keys.length - 1) {
                    current[k] = value;
                } else {
                    current[k] = current[k] || {};
                    current = current[k];
                }
            }
        });

        return parsedObj;
    }
    catch (e) {
        console.log("getQueryParams hatası", e)
        return {}
    }
}

const flattenParams = (prefix, obj, sort = false) => {
    try {
        let params = ''
        const addParams = (key, value) => {
            if (key){
                if (params) params += '&'
                params += `${key}=${value}`
            }
        }

        const createStringParams = (prefix, obj) => {
            Object.keys(obj).forEach(key => {
                const value = obj[key]
                const newKey = prefix ? `${prefix}[${key}]` : key

                if (Array.isArray(value)) {
                    value.forEach((val, index) => {
                        addParams(`${newKey}[${index}]`, val)
                    })
                }
                else if (typeof value === 'object' && value !== null) {
                    createStringParams(newKey, value)
                }
                else {
                    addParams(newKey, value)
                }
            })
        }

        createStringParams(prefix, obj)

        if (sort && params){
            return params
                .split('&')
                .map(e => e.split('='))
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map(e => e.join('='))
                .join('&')
        }

        return params.trim()
    }
    catch (e) {
        console.log("flattenParams hatası", e)
        return ''
    }
}

const isIosChromeAndEdgeBrowser = () => {
    return /CriOS|EdgiOS/.test(window.navigator.userAgent)
}

const getSocialMediaIcon = (socialMedia) => {
    switch (socialMedia) {
        case 'twitter':
            return ['fab', 'twitter'];
        case 'youtube':
            return ['fab', 'youtube'];
        case 'facebook':
            return ['fab', 'facebook-f'];
        case 'x':
            return ['fab', 'twitter'];
        case 'linkedin':
            return ['fab', 'linkedin'];
        case 'instagram':
            return ['fab', 'instagram'];
        case 'email':
            return ['fas', 'envelope'];
        default:
            return ['fas', 'share-square'];
    }
}

const createUrlForAssets = (path) => {
    if (!path) return ''
    if (path.startsWith('/')) path = path.substring(1)
    return `https://assets.sorgulamax.com/build/${path}`
}

export default {
    changeBodyOverflowY,
    convertStringDateToObjectDate,
    apiErrorHanding,
    localStorage: localStorageFunctions,
    handleDurationTime,
    priceFormat,
    //createCdnUrl,
    routeHandler,
    getQueryParams,
    flattenParams,
    isIosChromeAndEdgeBrowser,
    getSocialMediaIcon,
    dateFormat,
    createUrlForAssets
}
