import store from "@store/Store.js"
let tempFalseFilter = {}
let filters = null

const BusFilter = () => {
    filters = store.getters._getListPageFilters
    handleFilterOptions()
    const tempBusListData = store.getters._getSearchResult

    tempBusListData.journeys.forEach(bus => {
        bus.isHidden = false
        bus = controlBusByFilter(bus)
    })

    store.commit('setSearchResult', tempBusListData)
}

const handleFilterOptions = () => {
    tempFalseFilter = {
        SEAT_ARRANGEMENT: [],
        DEPARTURE_LOCATION: [],
        ARRIVAL_LOCATION: [],
        COMPANIES: [],
        PRICE: [],
        DEPARTURE_TIME: []
    }

    filters.forEach(filterGroup => {
        if (filterGroup.type !== "PRICE" && filterGroup.type !== "DEPARTURE_TIME"){
            const tempValue = filterGroup.filters.filter(filter => !filter.value)
            if (tempValue.length > 0) tempFalseFilter[filterGroup.type].push(tempValue)
        }
    })
}

const controlBusByFilter = (bus) =>{
    bus = checkboxFilters(bus)
    if(bus.isHidden) return bus

    bus = departureTimeRangeFilter(bus)
    if(bus.isHidden) return bus

    return priceFilter(bus)
}

const checkboxFilters = (bus) => {
    if (!bus.isHidden){
        Object.entries(tempFalseFilter).forEach(([filterType, value]) => {
            if (value.length > 0 && !bus.isHidden){
                bus = controlCheckboxFilter(bus,filterType)
            }
        })
    }
    return bus
}

const priceFilter = (bus) => {
    const maxPrice = filters
        .find(filter => filter.type === 'max-price-range')
        .filters[0]
        .value

    bus.isHidden = !(bus.price.total <= maxPrice)
    return bus
}

const departureTimeRangeFilter = (bus) => {
    const [minTime, maxTime] = filters
        .find(filter => filter.type === 'DEPARTURE_TIME')
        .filters[0]
        .value

    const hour = Number(bus.hour.split(':')[0])
    bus.isHidden = !(hour >= minTime && hour <= maxTime)
    return bus
}

const controlCheckboxFilter = (bus, filterType) => {
    tempFalseFilter[filterType].forEach(filterGroup => {
        filterGroup.forEach(filterItem => {
            if (!bus.isHidden) {
                const filterName = filterItem.filterName.replace('Koltuk', '').trim()
                bus.isHidden = filterName === getBusProperty(filterType, bus)
            }
        })
    })

    return bus
}

const getBusProperty = (filterType, bus) => {
    switch (filterType) {
        case 'SEAT_ARRANGEMENT': return bus.type
        case 'DEPARTURE_LOCATION': return bus.origin
        case 'ARRIVAL_LOCATION': return bus.destination
        case 'COMPANIES': return bus.company.name
        case 'PRICE': return bus.price.total
        default: return ''
    }
}

export default BusFilter
