import RentACarVuexHelpers from "@helpers/ForStore/Car.js"

const CarProducts = {
    state: {
        reservationPageCarProducts: null,
    },

    mutations: {
        setReservationPageCarProducts(state, products){
            state.reservationPageCarProducts = products
        },

        changeProductSelectedValueInReservationPageCar(state, productIndex){
            state.reservationPageCarProducts[productIndex].selected = !state.reservationPageCarProducts[productIndex].selected
        }
    },

    getters: {
        _getReservationPageCarProducts: state => state.reservationPageCarProducts,

        _getReservationPageCarSelectedProducts: state => RentACarVuexHelpers.getSelectedProducts(state.reservationPageCarProducts || []),
    }
}

export default CarProducts
