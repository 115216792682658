import FlightPassengers from "@store/Modules/Flight/Passengers.js";
import FlightProducts from "@store/Modules/Flight/Products.js";
import FlightPackages from "@store/Modules/Flight/Packages.js";
import FlightVuexHelpers from '@helpers/ForStore/Flight.js'

const Flight = {
    state: {
        ...FlightPassengers.state,

        ...FlightProducts.state,

        ...FlightPackages.state,
    },

    mutations: {
        ...FlightPassengers.mutations,

        ...FlightProducts.mutations,

        ...FlightPackages.mutations,

        resetAllFlightVuex(state){
            state.reservationPageFlightPassengersPrice = 0
            state.paymentPageFlightPassengersPrice = 0
            state.flightPassengersFormValues = null
            state.flightPassengersCount = 0
            state.flightProducts = null
        },
    },

    getters: {
        ...FlightPassengers.getters,

        ...FlightProducts.getters,

        ...FlightPackages.getters,

        _getReservationPageFlightTotalPrice: state => {
            return (
                state.reservationPageFlightPassengersPrice
                +
                FlightVuexHelpers.getFlightSelectedProductsPrice(
                    state.flightProducts,
                    state.flightPassengersCountWithoutBabyCount
                )
            )
        },
    },
}

export default Flight
