
const MiniInformationPopup = {
    state: {
        miniPopupItems: [],
    },

    mutations: {
        setMiniInformationPopup(state, miniPopupItem){
            state.miniPopupItems.push(miniPopupItem)
        },

        miniInformationPopupItemRemoveById(state, id){
            state.miniPopupItems = state.miniPopupItems.filter(item => {
                if (item.uuid !== id){
                    return item
                }
            })
        },

        setMiniInformationPopupItemToDefault(state){
            state.miniPopupItems = []
        },
    },

    actions:{
        _addMiniInformationPopupItem(context, value){
            if (context.getters._getIsMobile){
                const uuid = new Date().getTime() + value.message.toString().replace(/ /g, '')
                context.commit('setMiniInformationPopup', {...value, uuid})
                setTimeout(()=>{
                    context.commit('miniInformationPopupItemRemoveById', uuid)
                },9000)
            }
            else {
                context.commit('addApiErrorValue', {
                    title: value.status ? 'Başarılı' : 'Hata',
                    messages: [value.message],
                    callCenterMessage: '',
                    modalType: value.status ? 'success' : 'error',
                })
            }
        },
    },

    getters: {
        _getMiniInformationPopupItems: state => state.miniPopupItems,
    },
}

export default MiniInformationPopup
