const SMPoint = {
    state: {
        desiredSorgulamaxScoreToBeUsed: 0,
    },

    mutations: {
        setDesiredSorgulamaxScoreToBeUsed(state, value) {
            state.desiredSorgulamaxScoreToBeUsed = value
        },

        resetSMPointVuex(state){
            state.desiredSorgulamaxScoreToBeUsed = 0
        }
    },

    getters: {
        _getDesiredSorgulamaxScoreToBeUsed: state => state.desiredSorgulamaxScoreToBeUsed,
    }
}

export default SMPoint
