const FlightProducts = {
    state: {
        flightProducts: null,
    },

    mutations: {
        setFlightProducts(state, products){
            state.flightProducts = products
        },

        setFlightProductsSelectedValueByIndex(state, payload){
            state.flightProducts.forEach(product => {
                if (product.id === payload.id){
                    product.default_selected = payload.value
                }
            })
        },

        resetFlightProducts(state){
            state.flightProducts = null
        },
    },

    getters: {
        getFlightProducts: state => state.flightProducts,

        _getFlightReservationPageSelectedProducts: state => {
            return (state.flightProducts || [])
                .filter(product => product.default_selected)
                .map(product => product.id)
        },
    }
}

export default FlightProducts;
