import store from "@store/Store.js";

const isHasStorage = () => typeof localStorage !== "undefined"

const clearBeforeSearchBySearchType = (searchType) => {
    if (isHasStorage()){
        setBeforeSearchBySearchTypeFromStorage(searchType, [])
    }
}

const addSearchBySearchType = (searchType, newSearch) => {
    if (isHasStorage() && !!newSearch){
        checkAndCreateEmptyBeforeSearch()
        newSearch = checkGroupTypeAndTempId(newSearch)
        const allBeforeSearch = getAllBeforeSearchFromStorage()
        const targetBeforeSearch = allBeforeSearch ? allBeforeSearch[searchType] : []
        const beforeSearch = targetBeforeSearch?.length
            ? [searchType, [newSearch, ...(checkSameLocationInBeforeSearch(targetBeforeSearch, newSearch) || [])]]
            : [searchType, [newSearch]]
        setBeforeSearchBySearchTypeFromStorage(...beforeSearch)
    }
}

const addRoomSearch = (searchData) => {
    if (isHasStorage() && !!searchData){
        checkAndCreateEmptyBeforeSearch()
        const allBeforeSearch = getAllBeforeSearchFromStorage()
        const targetBeforeSearch = allBeforeSearch.hotel
        if (allBeforeSearch.hotel?.length){
            targetBeforeSearch[0].guestSize = searchData.guestSize
            targetBeforeSearch[0].dateValues = searchData.dateValues
            setBeforeSearchBySearchTypeFromStorage('hotel', targetBeforeSearch)
        }
    }
}

const checkGroupTypeAndTempId = (values) => {
    const locations = Array.isArray(values.locationValues)
        ? values.locationValues
        : Object.values(values.locationValues);
    locations.forEach(location => {
        if (location?.groupType) delete location.groupType;
        if (location?.tempId) delete location.tempId;
    })
    return values
}

const checkSameLocationInBeforeSearch = (beforeSearch, newSearch) => {
    if (beforeSearch?.length){
        beforeSearch.forEach((search, index)=>{
            if (JSON.stringify(search.locationValues) === JSON.stringify(newSearch.locationValues)){
                beforeSearch.splice(index,1)
            }
        })
    }
    return beforeSearch
}

const getAllBeforeSearchFromStorage = () => {
    checkAndCreateEmptyBeforeSearch()
    if (isHasStorage()){
        return JSON.parse(localStorage.getItem('beforeSearch'))
    }
    return {
        flight: [],
        car: [],
        bus: [],
        hotel: [],
    }
}

const setAllBeforeSearchFromStorage = (values) => {
    localStorage.setItem('beforeSearch', JSON.stringify(values))
    store.commit('setBeforeSearch', values)
}

const setBeforeSearchBySearchTypeFromStorage = (searchType, value) => {
    const allBeforeSearch = getAllBeforeSearchFromStorage()
    allBeforeSearch[searchType] = value.slice(0, 5)
    setAllBeforeSearchFromStorage(allBeforeSearch)
}

const checkAndCreateEmptyBeforeSearch = () => {
    if (isHasStorage() && !localStorage.getItem('beforeSearch')){
        setAllBeforeSearchFromStorage({
            flight: [],
            car: [],
            bus: [],
            hotel: [],
        })
    }
}

export default {
    addSearchBySearchType,
    addRoomSearch,
    getAllBeforeSearchFromStorage,
    clearBeforeSearchBySearchType
}
