import beforeSearch from "@helpers/LocalStorage/UserBeforeSearch.js"
import reservationPageUserValues from "@helpers/LocalStorage/ReservationPageUserValues.js";
import billing from "@helpers/LocalStorage/BillingForm/Index.js";

const setIsAuthenticated = (value) => {
    if (typeof localStorage !== "undefined"){
        localStorage.setItem('isAuthenticated', value)
    }
}

export default {
    setIsAuthenticated,
    beforeSearch,
    reservationPageUserValues,
    billing,
}
