import store from '@store/Store.js'
import helpers from '@helpers/Index.js'

const emptyFixedSearchForm = () => {
    const passengerTypes = [['adult', 'Yetişkin', ''], ['child', 'Çocuk', '(2 - 12 Yaş Arası)'], ['baby', 'Bebek', '(0 - 2 Yaş Arası)'], ['old', '65 yaş üstü', ''], ['student', 'Öğrenci', '(12 - 24 Yaş Arası)']]
    const passengers = {}

    passengerTypes.forEach((passengerType, index) => {
        passengers[passengerType[0]] = {
            name: passengerType[1],
            count: index === 0 ? 1 : 0,
            type: passengerType[0],
            description: passengerType[2],
            decrease: false,
            increase: true,
        }
    })
    const flightTypes = ['Hepsi', 'Ekonomi', 'Premium Ekonomi', 'Business'].map((flightType, index) => {
        return {
            name: flightType,
            isSelect: index === 0,
        }
    })

    return {
        locationValues: {
            departurePlace: null,
            landingPlace: null,
        },
        dateValues: {
            departureDate: null,
            landingDate: null,
        },
        passengerValues: {
            passengers,
            flightTypes,
        },
        isNonStop: true,
        isOneDirection: true,
    }
}

let passengerCount = 0

const createSearchFormValuesAndCalculatePassengerCount = () => {
    const searchForm = {...store.getters._getPageProps.searchForm}
    let tempFixedSearchForm = emptyFixedSearchForm()
    passengerCount = 0
    tempFixedSearchForm.isNonStop = setIsNonStop(searchForm)
    tempFixedSearchForm.locationValues = setLocationValues(searchForm)
    tempFixedSearchForm = setDateValues(searchForm, tempFixedSearchForm)
    tempFixedSearchForm = handlePassengersAndCalculatePassengerCount(searchForm.passengers, tempFixedSearchForm)
    newSearchFormCommitToStore(tempFixedSearchForm)
    return passengerCount
}

const handlePassengersAndCalculatePassengerCount = (passengers, tempFixedSearchForm) => {
    if (Object.keys(passengers).length > 0){
        passengerCount = Object.values(passengers).reduce((acc, curr) => Number(acc) + Number(curr), 0)

        const passengerTypes = {adult: 'ADT', child: 'CHD', baby: 'INF', old: 'YCD', student: 'STU'}

        Object.entries(passengerTypes).forEach(([key, value]) => {
            tempFixedSearchForm.passengerValues.passengers[key].count = Number(passengers[value]) || 0
        })
    }
    else{
        passengerCount = 1
        tempFixedSearchForm.passengerValues.passengers[0].count = 1
    }

    return tempFixedSearchForm
}

const setIsNonStop = (searchForm) => {
    return searchForm.filters && searchForm.filters.isNonStop && searchForm.filters.isNonStop === "1"
}

const setLocationValues = (searchForm) => {
    return {
        departurePlace: searchForm.origin,
        landingPlace: searchForm.destination,
    }
}

const setDateValues = (searchForm, tempFixedSearchForm) => {
    tempFixedSearchForm.dateValues.departureDate = helpers.convertStringDateToObjectDate(searchForm.departure_date, 'departure')

    if (searchForm.return_date){
        tempFixedSearchForm.isOneDirection = false
        tempFixedSearchForm.dateValues.landingDate = helpers.convertStringDateToObjectDate(searchForm.return_date, 'landing')
    }
    else{
        tempFixedSearchForm.dateValues.landingDate = createEmptyDatepickerLandingDateValues()
    }

    return tempFixedSearchForm
}

const createEmptyDatepickerLandingDateValues = () => {
    return {
        dayNumber: null,
        dayLong: null,
        dayShort: null,
        monthNumber: null,
        monthLong: null,
        monthShort: null,
        year: null,
        type: 'landing',
    }
}

const newSearchFormCommitToStore = (newSearchForm) => {
    store.commit('addBeforeSearch', {value: newSearchForm, type: 'flight'})
    store.commit('setTicketTabsSearchForm', {searchForm: newSearchForm, pageType: 'flight'})
}

export default createSearchFormValuesAndCalculatePassengerCount;
