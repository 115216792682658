import store from "@store/Store.js"
import {
    controlFlightByFilter,
    handleFilterOptions,
} from "@utils/Filters/Flight/GlobalFlightFiltersFunctions.js";

let filterOptions = {};

const resetFilter = () => {
    const tempFlightListData = JSON.parse(JSON.stringify(store.getters._getSearchResult))

    tempFlightListData.flightList.forEach(flightGroup => {
        flightGroup.isHidden = false
        Object.keys(flightGroup).forEach(flightType => {
            if (['departureFlights','returnFlights'].indexOf(flightType) !== -1){
                flightGroup[flightType].forEach(flight => {
                    flight.isHidden = false
                })
            }
        })
    })

    return tempFlightListData
}


const FilterInternationalFlights = () => {
    const tempFlightListData = resetFilter()

    if (tempFlightListData.flightList.toString().length > 0){
        filterOptions = handleFilterOptions(store.getters._getListPageFilters)

        tempFlightListData.flightList.forEach(flightGroup => {
            Object.keys(flightGroup).forEach(flightType => {
                if (['departureFlights','returnFlights'].indexOf(flightType) !== -1){
                    flightGroup[flightType].forEach(flight => {
                        flight.viewPrice = flightGroup.viewPrice
                        flight = controlFlightByFilter(flight, flightType, filterOptions)
                        flightGroup.isHidden = flightGroup.isHidden || flight.isHidden
                    })
                }
            })
        })

        store.commit('setSearchResult', tempFlightListData)
    }
}

export default FilterInternationalFlights

