import autocomplete from "@utils/Api/Hotel/Modules/Autocomplete.js";
import getHotels from "@utils/Api/Hotel/Modules/GetHotels.js";
import getHotel from "@utils/Api/Hotel/Modules/GetHotel.js";
import getRooms from "@utils/Api/Hotel/Modules/GetRooms.js";
import checkOffers from "@utils/Api/Hotel/Modules/CheckOffers.js";
import {reservation} from "@utils/Api/Hotel/Modules/Reservation.js";
import getHotelPictures from "@utils/Api/Hotel/Modules/GetHotelPictures.js";

export default {
    autocomplete,
    getHotels,
    getHotel,
    getRooms,
    checkOffers,
    reservation,
    getHotelPictures
}
