import store from "@store/Store.js";

export const getBillingInfoFromLocalStorage = () => {
    if (typeof localStorage !== "undefined"){
        const storageBillingFormValues = JSON.parse(localStorage.getItem('billing-form-values'))
        const storageBillingType = JSON.parse(localStorage.getItem('billing-form-type'))

        if (storageBillingType && storageBillingFormValues){
            store.commit('setBillingFormValues', {
                ...store.getters._getBillingFormValues,
                ...storageBillingFormValues
            })
            store.commit('setBillingType', storageBillingType)
        }
    }
}
