import store from "@store/Store.js";

let rooms = []

const handleRoomFilter = () => {
    const filters = findFalseFilters()
    rooms = removeFiltersInAllRooms()

    if (filters?.length){
        filters.forEach(filter => {
            handleFilter(filter)
        })
    }

    store.commit('setHotelRooms', rooms)
    rooms = []
}

const findFalseFilters = () => {
    return store.getters._getHotelRoomsFilters.reduce((falseFilterValues, filter, index) => {
        filter.filters.forEach((filterItem) => {
            if (!filterItem.value)
                falseFilterValues.push({
                    filterType: filter.type,
                    filterItem,
                })
        })
        return falseFilterValues
    }, [])
}

const handleFilter = ({filterType, filterItem}) => {
    rooms.forEach(room => {
        if (['ADULT_COUNT', 'CHILD_COUNT', 'BABY_COUNT'].includes(filterType)) {
            room = handleGuestCountFilter(
                room,
                filterItem,
                getFilterType(filterType)
            )
        }
        else if (filterType === 'CONCEPTS'){
            room.offers.forEach(offer => {
                if (offer.concept === filterItem.name) offer.isHidden = true
            })
        }
        else if (filterType === 'CANCELLATION_POLICY'){
            room.offers.forEach(offer => {
                if (offer.cancellation_policy.label === filterItem.name) offer.isHidden = true
            })
        }
        else{
            room = handleGiftsAndBedOptionFilter(
                room,
                filterItem,
                getFilterType(filterType)
            )
        }
    })
}

const getFilterType = (filterType) => {
    return filterType === 'ADULT_COUNT' ?
        'adult' :
        filterType === 'FREE_CHILD_COUNT' ?
            'free_children' :
            filterType === 'BABY_COUNT' ?
                'baby' :
                filterType === 'GIFTS' ?
                    'gifts' :
                    filterType === 'BED_TYPE' ?
                        'bed_options' :
                        filterType === 'CANCELLATION_POLICY' ?
                            'cancellationPolicy' :
                            'concept'
}

const handleGuestCountFilter = (room, filterItem, type) => {
    return room.offers.forEach(offer => {
        if (offer.pax_count[type] === filterItem.name) offer.isHidden = true
    })
}

const handleGiftsAndBedOptionFilter = (room, filterItem, type) => {
    if (type === 'gifts'){
        Object.values(room.gifts).forEach(gift => {
            if (gift.label === filterItem.name){
                room = allOffersToHide(room)
            }
        })
    }
    else{
        room.bed_options.forEach(bed_option => {
            if (bed_option.name === filterItem.name){
                room = allOffersToHide(room)
            }
        })
    }

    return room
}

const allOffersToHide = (room) => {
    return room.offers.forEach(offer => {
        offer.isHidden = true
    })
}

const removeFiltersInAllRooms = () => {
    return store.getters._getHotelRooms.map(room => {
        room.offers.map(offer => {
            offer.isHidden = false
        })
        return room
    })
}


export default handleRoomFilter;
