import GlobalAxios from "@utils/Api/GlobalAxios.js";

const privacyPolicy = () => {
    return GlobalAxios.Api.get('/contracts/privacy-policy')
}

const termsOfUse = () => {
    return GlobalAxios.Api.get('/contracts/terms-of-use')
}

export default {
    privacyPolicy,
    termsOfUse,
}
