import FavoriteList from "@store/Modules/User/FavoriteList.js";
import BeforeSearch from "@store/Modules/User/BeforeSearch.js";
import PriceAlerts from "@store/Modules/User/PriceAlerts.js";
import Notifications from "@store/Modules/User/Notifications.js";

const User = {
    state: {

    },

    mutations: {

    },

    getters: {

    },

    modules: {
        FavoriteList,
        BeforeSearch,
        PriceAlerts,
        Notifications,
    }
}

export default User
