import helpers from "@helpers/Index.js";

export const handleFilterOptions = (filters) => {
    const filterOptions = {
        transferSizeFilterOptions: [],
        ticketPriceFilterOptions: [],
        departureArrivalTimeFilterOptions: [],
        flightTimeFilterOptions: [],
        checkboxMoreChoseFilterOptions: [],
        baggageInfoFilterOptions: [],
    }


    filters.forEach(filterGroup => {
        const tempValue = filterGroup.filters.filter(filter => {
            if (filterGroup.type === 'ticket-price' || filterGroup.type === 'flight-departure-arrival-time' || filterGroup.type === 'flight-time') {
                return filter.value.toString() !== filter.max.toString()
            }
            else if (filterGroup.type === 'BAGGAGE_INFO'){
                return filter.value
            }
            else {
                return !filter.value;
            }
        });

        if (tempValue.length > 0){
            switch (filterGroup.type) {
                case 'transfer-size':
                    filterOptions.transferSizeFilterOptions.push(tempValue)
                    break
                case 'ticket-price':
                    filterOptions.ticketPriceFilterOptions.push(filterGroup.filters)
                    break
                case 'flight-departure-arrival-time':
                    filterOptions.departureArrivalTimeFilterOptions.push(tempValue)
                    break
                case 'flight-time':
                    filterOptions.flightTimeFilterOptions.push(filterGroup.filters)
                    break
                case 'checkbox-more-chose':
                    filterOptions.checkboxMoreChoseFilterOptions.push(tempValue)
                    break
                case 'BAGGAGE_INFO':
                    filterOptions.baggageInfoFilterOptions.push(tempValue)
                    break
            }
        }
    })

    return filterOptions
}

let filterOptions = {}

export const controlFlightByFilter = (flight, flightType, filterOptionsProp) =>{
    filterOptions = filterOptionsProp
    flight = checkboxFilters(flight)
    if(flight.isHidden){
        return flight
    }
    flight = rangeFilters(flight,flightType)

    return flight
}

const checkboxFilters = (flight) => {
    if (!flight.isHidden){
        if (filterOptions.transferSizeFilterOptions.length > 0){
            flight = transferFilter(flight)
            if(flight.isHidden){
                return flight
            }
        }

        if (filterOptions.checkboxMoreChoseFilterOptions.length > 0){
            flight = airlineOrAirportFilter(flight)
            if(flight.isHidden){
                return flight
            }
        }

        if (filterOptions.baggageInfoFilterOptions.length > 0){
            flight = baggageFilter(flight)
        }
        else {
            flight.isHidden = true
        }
    }

    return flight
}

const transferFilter = (flight) => {
    filterOptions.transferSizeFilterOptions.forEach((transferFilter) => {
        transferFilter.forEach(transferFilterItem => {
            if (!flight.isHidden){
                flight.isHidden = flight.isHidden || checkFlightType(transferFilterItem.filterType, flight.segments.length)
            }
        })
    })

    return flight
}

const checkFlightType = (transferType, segmentsLength) =>{
    if (transferType === 'GET_DIRECT_FLIGHT')
        return segmentsLength === 1;
    else if (transferType === 'MORE_TRANSFER')
        return segmentsLength > 2;
    else
        return segmentsLength === 2;
}

const airlineOrAirportFilter = (flight) => {
    filterOptions.checkboxMoreChoseFilterOptions.forEach(checkboxFilter => {
        checkboxFilter.forEach(checkboxFilterItem => {
            if (!flight.isHidden){
                flight.segments.forEach(segmentFlight => {
                    if (checkboxFilterItem.filterType === "AIRPORT"){
                        if (segmentFlight.departureAirport === checkboxFilterItem.airlineOrAirportName || segmentFlight.arrivalAirport === checkboxFilterItem.airlineOrAirportName){
                            flight.isHidden = true
                        }
                    }
                    else {
                        if(segmentFlight.airline === checkboxFilterItem.airlineOrAirportName){
                            flight.isHidden = true
                        }
                    }
                })
            }
        })
    })

    return flight
}

const baggageFilter = (flight) => {
    //if (!flight.isHidden) {
    //    const getMaxBaggage = (baggageInfo) => {
    //        switch (baggageInfo.unit) {
    //            case 'KILO': return baggageInfo.quantity
    //            case 'PIECE': return baggageInfo.quantity * 23
    //            case 'HAND_BAGGAGE': return 0
    //        }
    //    }
    //
    //    filterOptions.baggageInfoFilterOptions.forEach(baggageFilter => {
    //        baggageFilter.forEach(baggageFilterItem => {
    //            Object.keys(flight.baggageInfo).forEach(baggage => {
    //                console.log(baggageFilterItem.baggageOptions)
    //                console.log(flight.baggageInfo[baggage])
    //                console.log("\n")
    //                if (baggageFilterItem.baggageOptions.quantity === 0) {
    //                    flight.isHidden = true
    //                }
    //                else if (getMaxBaggage(baggageFilterItem.baggageOptions) === getMaxBaggage(flight.baggageInfo[baggage])) {
    //                    flight.isHidden = true
    //                }
    //            })
    //        })
    //    })
    //}

    //filterOptions.baggageInfoFilterOptions.forEach(baggageFilter => {
    //    baggageFilter.forEach(baggageFilterItem => {
    //        if (!flight.isHidden){
    //            Object.keys(flight.baggageInfo).forEach(baggage => {
    //                if (baggageFilterItem.baggageOptions.quantity === 0){
    //                    if (flight.baggageInfo[baggage].quantity === 0){
    //                        flight.isHidden = true
    //                    }
    //                }
    //                else if (JSON.stringify(baggageFilterItem.baggageOptions) === JSON.stringify(flight.baggageInfo[baggage])){
    //                    flight.isHidden = true
    //                }
    //            })
    //        }
    //    })
    //})

    if (!flight.isHidden){
        const getBaggageSize = (baggageInfo) => {
            switch (baggageInfo.unit) {
                case 'KILO': return baggageInfo.quantity
                case 'PIECE': return baggageInfo.quantity * 23
                case 'HAND_BAGGAGE': return 0
            }
        }
        let minBaggageSize = []
        filterOptions.baggageInfoFilterOptions.forEach(baggageFilter => {
            baggageFilter.forEach(baggageFilterItem => {
                minBaggageSize.push(getBaggageSize(baggageFilterItem.baggageOptions))
            })
        })
        minBaggageSize = Math.min(...minBaggageSize)

        Object.keys(flight.baggageInfo).forEach(baggage => {
            if (minBaggageSize > getBaggageSize(flight.baggageInfo[baggage])){
                flight.isHidden = true
            }
        })
    }

    return flight
}

const rangeFilters = (flight,flightType) => {
    if (!flight.isHidden){
        if (filterOptions.ticketPriceFilterOptions.length > 0){
            flight = priceFilter(flight, flightType)
            if (flight.isHidden){
                return flight
            }
        }

        if (filterOptions.departureArrivalTimeFilterOptions.length > 0){
            flight = flightDateTimeBetweenFromDepartureDateTimeOfArrivalDateTime(flight,flightType)
            if (flight.isHidden){
                return flight
            }
        }

        if (filterOptions.flightTimeFilterOptions.length > 0){
            flight = flightTimeFilter(flight, flightType)
        }
    }

    return flight
}

const priceFilter = (flight,flightType) => {
    filterOptions.ticketPriceFilterOptions.forEach(ticketPriceFilter => {
        ticketPriceFilter.forEach(ticketPriceFilterItem => {
            if (!flight.isHidden){
                if (ticketPriceFilterItem.filterType === 'INTERNATIONAL_MAX_PRICE'){
                    if (flightType === 'returnFlights' || flightType === 'departureFlights'){
                        flight.isHidden = flight.viewPrice > Number(ticketPriceFilterItem.value)
                    }
                }
                else if (ticketPriceFilterItem.filterType === 'DEPARTURE_MAX_PRICE'){
                    if (flightType === 'departure'){
                        flight.isHidden = flight.viewPrice > Number(ticketPriceFilterItem.value)
                    }
                }
                else if (ticketPriceFilterItem.filterType === 'RETURN_MAX_PRICE'){
                    if (flightType === 'return'){
                        flight.isHidden = flight.viewPrice > Number(ticketPriceFilterItem.value)
                    }
                }
            }
        })
    })

    return flight
}

const flightDateTimeBetweenFromDepartureDateTimeOfArrivalDateTime = (flight, flightType) => {

    const flightDateTimeEqualsFilterSelectValue = (filterValue, dateTimeType) => {
        const time = Number(helpers.dateFormat.getTimeInDate(flight[dateTimeType]).split(":")[0])
        flight.isHidden = !(time >= filterValue[0] && time <= filterValue[1])
    }

    filterOptions.departureArrivalTimeFilterOptions.forEach(departureArrivalTimeFilter => {
        departureArrivalTimeFilter.forEach(departureArrivalTimeFilterItem => {
            if (!flight.isHidden){
                const dateTimeType = departureArrivalTimeFilterItem.filterType.endsWith('DEPARTURE_TIME') ? 'departureDatetime' : 'arrivalDatetime'
                if ((departureArrivalTimeFilterItem.filterType.startsWith("RETURN") && flightType.startsWith('return')) || departureArrivalTimeFilterItem.filterType.startsWith("DEPARTURE") && flightType.startsWith('departure')){
                    flightDateTimeEqualsFilterSelectValue(departureArrivalTimeFilterItem.value, dateTimeType)
                }
            }
        })
    })

    return flight
}

const flightTimeFilter = (flight, flightType) => {

    filterOptions.flightTimeFilterOptions.forEach(flightTimeFilter => {
        flightTimeFilter.forEach(flightTimeFilterItem => {
            if (!flight.isHidden){
                if ((flightTimeFilterItem.filterType.startsWith("RETURN") && flightType.startsWith('return')) || flightTimeFilterItem.filterType.startsWith("DEPARTURE") && flightType.startsWith('departure')){
                    flight.isHidden = timeFilter(flightTimeFilterItem.value, flight.duration)
                }
            }
        })
    })

    return flight
}

const timeFilter = (filterValue, {day, hours, minutes}) => {
    return ((day * 60 * 24) + (hours * 60) + minutes) >= filterValue
}
