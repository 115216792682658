import {changeDatepickerValues} from "@helpers/TicketTabs/Car.js";
import {checkBeforeSearchDates} from "@helpers/TicketTabs/Global.js";

const TicketTabs = {
    state: {
        searchTabTitles: [
            {
                tabComponent: 'FlightTickedTab',
                tabId: 0,
                content: 'Uçak Bileti',
                mobileContent: 'Uçak',
                isLink: false,
                target: '',
                route: 'flight.home',
                icon: 'plane',
            },
            {
                tabComponent: 'HotelTab',
                tabId: 1,
                content: 'Otel',
                mobileContent: 'Otel',
                isLink: false,
                target: '',
                route: 'hotel.home',
                icon: 'bed',
            },
            {
                tabComponent: 'BusTickedTab',
                tabId: 2,
                content: 'Otobüs Bileti',
                mobileContent: 'Otobüs',
                isLink: false,
                target: '',
                route: 'bus.home',
                icon: 'bus-alt',
            },
            {
                tabComponent: 'RentCarTab',
                tabId: 3,
                content: 'Araç Kiralama',
                mobileContent: 'Araç',
                isLink: false,
                target: '',
                route: 'rentACar.home',
                icon: 'car',
            },
        ],

        ticketTabsSearchForm: {
            flight: null,
            hotel: null,
            bus: null,
            car: null,
        },

        carTicketTabsTimePickerProps: {
            delivery: [],
            pickup: [],
        },

        carPageTargetBrandName: null,
    },

    mutations: {
        setCarPageTargetBrandName(state, brandName) {
            state.carPageTargetBrandName = brandName
        },

        setTicketTabsSearchForm(state, {searchForm, pageType}) {
            state.ticketTabsSearchForm[pageType] = searchForm
        },

        setTicketTabsSearchFormAndCheckDatesWithPageType(state, data) {
            data.searchForm = JSON.parse(JSON.stringify(checkBeforeSearchDates(data.searchForm, data.pageType)))
            state.ticketTabsSearchForm[data.pageType] = JSON.parse(JSON.stringify(data.searchForm))
        },

        setTicketTabsSearchFormLocationValuesWithKey(state, data) {
            state.ticketTabsSearchForm[data.pageType].locationValues[data.key] = data.value
        },

        setTicketTabsSearchFormWithDoubleKey(state, data) {
            state.ticketTabsSearchForm[data.pageType][data.parentKey][data.childKey] = data.value
        },

        setTicketTabsSearchFormWithDoubleKeyForCar(state, value) {
            changeDatepickerValues(value)
        },

        setTicketTabsSearchFormWithKey(state, data) {
            state.ticketTabsSearchForm[data.pageType][data.key] = data.value
        },

        setCarTicketTabsTimePickerProps(state, timeValues) {
            state.carTicketTabsTimePickerProps = JSON.parse(JSON.stringify(timeValues))
        },
    },

    getters: {
        _getSearchTabTitles: state => state.searchTabTitles,

        _getTicketTabsSearchForm: state => state.ticketTabsSearchForm,

        _getFlightTabSearchForm: state => state.ticketTabsSearchForm.flight,
        _getFlightTabSearchFormWithKey: state => (key) => state.ticketTabsSearchForm?.flight[key] || null,

        _getBusTabSearchForm: state => state.ticketTabsSearchForm.bus,

        _getCarTabSearchForm: state => state.ticketTabsSearchForm.car,
        _getCarTicketTabsTimePickerProps: state => JSON.parse(JSON.stringify(state.carTicketTabsTimePickerProps)),
        _getCarPageTargetBrandName: state => state.carPageTargetBrandName,

        _getHotelTabSearchForm: state => state.ticketTabsSearchForm.hotel,
    },
}

export default TicketTabs;
