import RentACarVuexHelpers from "@helpers/ForStore/Car.js"
import helpers from "@helpers/Index.js"

const CarDriver = {
    state: {
        rentACarDriverFormValues: null,
        rentACarPrice: 0
    },

    mutations: {
        setReservationPageRentACarDriverFormValues(state){
            state.rentACarDriverFormValues = RentACarVuexHelpers.createEmptyDriverFormValuesWithPageType(null)
        },

        setPaymentPageRentACarDriverFormValues(state, rawDriverValues){
            state.rentACarDriverFormValues = RentACarVuexHelpers.createEmptyDriverFormValuesWithPageType(rawDriverValues)
        },

        setRentACarPrice(state, price){
            state.rentACarPrice = price
        },

        setRentACarDriverFormValues(state, driverForm){
            state.rentACarDriverFormValues = driverForm
        },

        setRentACarDriverFormValuesForSelectPassengerList(state, driverForm){
            state.rentACarDriverFormValues = driverForm
            helpers.localStorage
                .reservationPageUserValues
                .setCarPassengersInLocaleStorage()
        },

        setRentACarDriverFormValuesByInputType(state, payload){
            state.rentACarDriverFormValues[payload.inputType] = payload.value
            helpers.localStorage
                .reservationPageUserValues
                .setCarPassengersInLocaleStorageOneByOne(payload.value.value, payload.inputType)
        },
    },

    getters: {
        _getRentACarDriverFormValues: state => state.rentACarDriverFormValues,

        _getRentACarPrice: state => state.rentACarPrice,
    }
}

export default CarDriver
