import GlobalAxios from "@utils/Api/GlobalAxios.js";
import store from "@store/Store.js";
import helpers from "@helpers/Index.js";
import {router} from "@inertiajs/vue3"

const checkOffers = () => {
    if (!store.getters._getHotelActivityId) return false

    const roomData = {
        id: store.getters._getHotelActivityId,
        offers: {}
    }

    store.getters._getSelectedRooms.map((room, index) => {
        roomData.offers[index] = {
            id: room.offer_id,
            quantity: room.roomCount,
        }
    })

    if (store.getters._getAgentCode) roomData.agent_code = store.getters._getAgentCode

    return GlobalAxios.Api.post('hotel/availability/rooms/offers', roomData)
        .then(response => {
            return !!response?.data?.data ? roomData : false
        })
        .catch(error => {
            helpers.apiErrorHanding.errorHandle(error)
            return false
        })
}

export default checkOffers;
