import helpers from "@helpers/Index.js";

const Auth = {
    state: {
        user: null,
    },

    mutations: {
        setUser(state, user){
            helpers.localStorage.setIsAuthenticated((!!user).toString())
            state.user = user;
        },
    },

    getters: {
        _getCurrentUser: state => state.user,

        _isAuthenticated: state => !!state.user,
    },
}

export default Auth
