import helpers from "@helpers/Index.js";

const ReservationGuests = {
    state: {
        hotelGuestInformationValues: null,
    },

    mutations: {
        setHotelGuestsValues(state, guests) {
            state.hotelGuestInformationValues = JSON.parse(JSON.stringify(guests))
        },

        setHotelCurrentGuestValues(state, currentGuest) {
            state.hotelGuestInformationValues.currentGuest = currentGuest;
            helpers.localStorage
                .reservationPageUserValues
                .setHotelCurrentGuestsInLocaleStorage(currentGuest.name.value, currentGuest.surname.value)
            state.hotelGuestInformationValues.rooms[0].guests[0].name = {
                ...state.hotelGuestInformationValues.rooms[0].guests[0].name,
                value: currentGuest.name.value,
                result: currentGuest.name.result,
                errorMessage: currentGuest.name.errorMessage,
            }
            state.hotelGuestInformationValues.rooms[0].guests[0].surname = {
                ...state.hotelGuestInformationValues.rooms[0].guests[0].surname,
                value: currentGuest.surname.value,
                result: currentGuest.surname.result,
                errorMessage: currentGuest.surname.errorMessage,
            }
        },

        setHotelOfferGuestValues(state, {offerID, guestIndex, offerRoomIndex, inputType, guestValues}) {
            const room = state.hotelGuestInformationValues.rooms
                .filter(room => room.offerId === offerID)[offerRoomIndex]

            if (!room) return;

            room.guests[guestIndex][inputType] = guestValues

            const targetRoomIndex = state.hotelGuestInformationValues.rooms.findIndex(hotelRoom => JSON.stringify(hotelRoom) === JSON.stringify(room))

            helpers.localStorage
                .reservationPageUserValues
                .setHotelOfferGuestsInLocaleStorageWithType(targetRoomIndex, guestIndex, inputType, guestValues.value)
        },

        setHotelOfferSelectedBedOption(state, {offerID, offerRoomIndex, bed}) {
            state.hotelGuestInformationValues.rooms
                .filter(room => room.offerId === offerID)[offerRoomIndex].selectedBed = bed
        },

        setHotelGuestNote(state, note) {
            state.hotelGuestInformationValues.note = note;
        },

        setGuestHoneyMoonValue(state, value) {
            state.hotelGuestInformationValues.isHoneymoon = value;
        },

        setHotelGuestCheckInTime(state, time) {
            state.hotelGuestInformationValues.checkinTime = time;
        },
    },

    getters: {
        _getHotelGuestsValues: state => state.hotelGuestInformationValues,

        _getHotelGuestInformationValuesWithOfferId: state => (offerId) => {
            return state.hotelGuestInformationValues.rooms
                .filter(room => room.offerId === offerId)
        },

        _getHotelGuestsValuesForReservationApi: state => {
            if (!state.hotelGuestInformationValues) return null
            const handledData = {}
            state.hotelGuestInformationValues.rooms.forEach((room, roomIndex) => {
                handledData[`${roomIndex}`] = {
                    offer_id: room.offerId,
                    guests: {},
                }
                if (room.selectedBed && room.selectedBed?.id){
                    handledData[`${roomIndex}`].bed_option = room.selectedBed.id
                }
                room.guests.forEach((guest, guestIndex) => {
                    const saveValues = {}
                    if (guest.saveProps?.isSave?.value) {
                        saveValues.add_to_passenger_list = true
                    }
                    else if (guest.saveProps?.isUpdate?.value){
                        saveValues.update_from_passenger_list = true
                        saveValues.ref_id = guest.saveProps.isUpdate.id
                    }

                    handledData[`${roomIndex}`].guests[`${guestIndex}`] = {
                        name: guest.name.value,
                        surname: guest.surname.value,
                        ...saveValues,
                    }
                })
            })
            return handledData
        },

        _getHotelCurrentGuestValuesForReservationApi: state => {
            if (!state.hotelGuestInformationValues) return null
            return {
                name: state.hotelGuestInformationValues.currentGuest.name.value,
                surname: state.hotelGuestInformationValues.currentGuest.surname.value,
            }
        },

        _getHotelCurrentGuestSaveValuesForReservationApi: state => {
            const saveProps = state.hotelGuestInformationValues?.currentGuest?.saveProps
            if (!saveProps) return {}
            if (saveProps?.isSave?.value) {
                return {
                    add_to_passenger_list: true,
                }
            }
            else if (saveProps?.isUpdate?.value){
                return {
                    update_from_passenger_list: true,
                    ref_id: saveProps.isUpdate.id
                }
            }
            return {}
        },

        _getHotelGuestNoteForReservationApi: state => state.hotelGuestInformationValues?.note,

        _getHotelGuestHoneyMoonValueForReservationApi: state => state.hotelGuestInformationValues?.isHoneymoon,

        _getHotelGuestCheckInTime: state => state.hotelGuestInformationValues?.checkinTime,
    },
}

export default ReservationGuests;
