import helpers from "@helpers/Index.js";

const BeforeSearch = {
    state: {
        userBeforeSearch: {
            flight: [],
            bus: [],
            car: [],
            hotel: [],
        },
    },

    mutations: {
        setBeforeSearch(state, values){
            state.userBeforeSearch = values
        },

        addBeforeSearch(state, values){
            helpers.localStorage.beforeSearch.addSearchBySearchType(values.type, values.value)
        },
    },

    getters: {
        _getBeforeSearch: (state) => (pageType) => {
            if (typeof localStorage !== "undefined"){
                state.userBeforeSearch = helpers.localStorage.beforeSearch.getAllBeforeSearchFromStorage()
            }
            if (pageType === 'dashboard'){
                return  state.userBeforeSearch.flight
            }
            return  state.userBeforeSearch[pageType]
        },
    },
}

export default BeforeSearch
