import store from "@store/Store.js"
let tempFalseFilter = {},
    minMaxValues = {}

const handleFilterOptions = (filters) => {
    tempFalseFilter = {
        TRANSMISSION: [],
        DELIVERY_TYPE: [],
        FUEL: [],
        VENDOR: [],
        CAR_CLASS: [],
        CAR_BRAND_NAME: [],
        CAR_MODEL: [],
    }
    minMaxValues = {}

    filters.forEach(filterGroup => {
        if (filterGroup.type !== 'min-max-input'){
            const tempValue = filterGroup.filters.filter(filter => !filter.value)
            if (tempValue.length > 0) tempFalseFilter[filterGroup.type].push(tempValue)
        }
        else{
            const { min, max } = filterGroup.filters[0]
            if (min.value) minMaxValues.min = min.value
            if (max.value) minMaxValues.max = max.value
        }
    })
}

const Filter = () => {
    handleFilterOptions(store.getters._getListPageFilters)
    const tempCarsListData = JSON.parse(JSON.stringify(store.getters._getSearchResult))
    tempCarsListData.cars.forEach(car => {
        car.isHidden = false
        car = controlCarByFilter(car)
    })

    store.commit('setSearchResult', tempCarsListData)
}

const controlCarByFilter = (car) =>{
    car = checkboxFilters(car)

    if(car.isHidden) return car

    return minMaxFilters(car)
}

const checkboxFilters = (car) => {
    if (!car.isHidden){
        Object.keys(tempFalseFilter).forEach(filterType => {
            if (tempFalseFilter[filterType].length > 0 && !car.isHidden){
                car = controlCheckboxFilter(car,filterType)
            }
        })
    }
    return car
}

const minMaxFilters = (car) => {
    Object.keys(minMaxValues).forEach(valueType => {
        if (!car.isHidden){
            car.isHidden = valueType === 'min'
                ? !(car.pricing.price_to_charge >= minMaxValues[valueType])
                : !(car.pricing.price_to_charge <= minMaxValues[valueType])
        }
    })

    return car
}

const controlCheckboxFilter = (car, filterType) => {
    tempFalseFilter[filterType].forEach(filterGroup => {
        filterGroup.forEach(filterItem => {
            if (!car.isHidden) {
                car.isHidden = filterItem.filterName === getCarProperty(filterType, car)
            }
        })
    })

    return car
};

const getCarProperty = (filterType, car) => {
    switch (filterType) {
        case 'TRANSMISSION': return car.car.transmission_text
        case 'DELIVERY_TYPE': return car.vendor_office.delivery_type_text
        case 'FUEL': return car.car.fuel_text
        case 'VENDOR': return car.vendor.name
        case 'CAR_CLASS': return car.car.class_text
        case 'CAR_BRAND_NAME': return car.car.brand_name
        case 'CAR_MODEL': return car.car.name
        default: return ''
    }
}


export default Filter
