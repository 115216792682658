import GlobalAxios from "@utils/Api/GlobalAxios.js";
import helpers from "@helpers/Index.js"
import store from "@store/Store.js";

const addComment = (blogId, data) => {
    return GlobalAxios.Api.post(
        `/comments/${blogId}`,
        data
    )
        .then((response) => {
            if (response.data.message){
                store.dispatch('_addMiniInformationPopupItem', {
                    status: true,
                    message: response.data.message
                })
            }
            else{
                const tempStorePageProps = store.getters._getPageProps
                tempStorePageProps.comments.push(...response.data.data)
                store.commit('setPageProps',tempStorePageProps)
            }
            return true
        })
        .catch(error => {
            helpers.apiErrorHanding.errorHandle(error)
            return false
        })
}

export default {
    addComment
}
