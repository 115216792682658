const localeDateNames = {
    'tr-TR': {
        monthLongNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        monthShortNames: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
        dayLongNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
        dayShortNames: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
    },
    'en-US': {
        monthLongNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthShortNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        dayLongNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayShortNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    }
}

const getTimeInDate = (date) => {
    if (!date || typeof date !== 'string' || !date.includes('T')) return '';
    return date.match(/T(\d{2}:\d{2})/)[1]
}

const padZero = (num) => {
    return Number(num) < 10 ? '0' + num : num;
}

const checkAndSetDate = (date, withTimeZone = false) => {
    if (!date) return new Date();
    if (withTimeZone) return new Date(date);
    if (typeof date === 'string') {
        if (date.includes('T')) {
            return new Date(date.split('T')[0]);
        }
        return new Date(date);
    }
    return date;
}

const getWeekday = (date, type = 'long', locale = 'tr-TR') => {
    date = checkAndSetDate(date);
    return type === 'short'
        ? localeDateNames[locale].dayShortNames[date.getDay()]
        : localeDateNames[locale].dayLongNames[date.getDay()];
}

const getYear = (date, type = 'numeric') => {
    date = checkAndSetDate(date);
    return type === '2-digit'
        ? date.getFullYear().toString().slice(-2)
        : date.getFullYear();
}

const getMonth = (date, type = '2-digit', locale = 'tr-TR') => {
    date = checkAndSetDate(date);
    switch (type) {
        case 'short': return localeDateNames[locale].monthShortNames[date.getMonth()];
        case 'numeric': return date.getMonth() + 1;
        case 'long': return localeDateNames[locale].monthLongNames[date.getMonth()];
        default: return padZero(date.getMonth() + 1);
    }
}

const getDay = (date, type = '2-digit') => {
    date = checkAndSetDate(date);
    return type === 'numeric' ? date.getDate() : padZero(date.getDate());
}

const getLocaleDateString = (date, locale = 'tr-TR', options = null, withTimeZone = false) => {
    date = checkAndSetDate(date, withTimeZone);

    if (!options) return `${getDay(date)} ${getMonth(date)} ${getYear(date)}`;

    return Object.entries(options).reduce((formattedDate, [key, type]) => {
        switch (key) {
            case 'weekday': return `${formattedDate}${getWeekday(date, type, locale)} `;
            case 'year': return `${formattedDate}${getYear(date, type)} `;
            case 'month': return `${formattedDate}${getMonth(date, type, locale)} `;
            case 'day': return `${formattedDate}${getDay(date, type)} `;
            default: return formattedDate;
        }
    }, '').trim();
}

export default {
    getTimeInDate,
    getWeekday,
    getYear,
    getMonth,
    getDay,
    getLocaleDateString
};
