import GlobalAxios from "@utils/Api/GlobalAxios.js";
import Helpers from "@helpers/Index.js";

const autocomplete =  (searchValue) => {
    return GlobalAxios.Api.get(
        `/rent-a-car/autocomplete?term=${searchValue}`,
    )
}

const getCars =  () => {
    return GlobalAxios.Api.post(
        '/rent-a-car/get-cars',
        Helpers.getQueryParams(),
        {
            headers: {
                'Accept': 'application/json'
            }
        }
    )
}

const getCar =  (carInfo) => {
    return GlobalAxios.Api.post(
        '/rent-a-car/get-car',
        {
            ...carInfo
        }
    )
}

const reservation =  (reservationAndPaymentValues) => {
    return GlobalAxios.Api.post(
        '/rent-a-car/prepare-order',
        {
            ...reservationAndPaymentValues
        }
    )
}

export default {
    autocomplete,
    getCars,
    getCar,
    reservation,
}
