
const Contracts = {
    state: {
        modalType: null
    },

    mutations: {
        setContractsModalValues(state,values){
            state.modalType = values
        }
    },

    getters: {
        _getContractsModalType: state => state.modalType
    },

}

export default Contracts;
