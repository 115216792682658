import store from "@store/Store.js";

let createdFilters = []

export const createRoomFilters = () => {
    createdFilters = []
    if (store.getters._getHotelRooms && store.getters._getHotelRooms.length > 0) {
        const {
            adultCount,
            freeChildCount,
            babyCount,
            concepts,
            bedTypes,
            newGifts,
            cancellationPolicy,
        } = handleRoomsData()

        if (adultCount.length > 1) createFilter(adultCount, 'Yetişkin Sayısı', 'ADULT_COUNT')
        if (freeChildCount.length > 1) createFilter(freeChildCount, 'Ücretsiz Çocuk Sayısı', 'FREE_CHILD_COUNT')
        if (babyCount.length > 1) createFilter(babyCount, 'Ücretsiz Bebek Sayısı', 'BABY_COUNT')
        if (concepts.length > 1) createFilter(concepts, 'Konseptler', 'CONCEPTS')
        if (cancellationPolicy.length > 1) createFilter(cancellationPolicy, 'İptal Seçenekleri', 'CANCELLATION_POLICY')
        if (bedTypes.length > 1) createFilter(bedTypes, 'Yatak Tipi', 'BED_TYPE')
        if (newGifts.length > 1) createFilter(newGifts, 'Ek Özellikler', 'GIFTS')
    }
    return createdFilters
}

const handleRoomsData = () => {
    const data = {
        adultCount: new Set(),
        freeChildCount: new Set(),
        babyCount: new Set(),
        concepts: new Set(),
        bedTypes: new Set(),
        newGifts: new Set(),
        cancellationPolicy: new Set()
    }

    store.getters._getHotelRooms.forEach(room => {
        const { bed_options, gifts } = room

        if (gifts) Object.values(gifts).forEach(gift => data.newGifts.add(gift.label))

        bed_options.forEach(bed_option => data.bedTypes.add(bed_option.name))

        room.offers.forEach(offer => {
            const { concept, cancellation_policy } = offer
            const { adult, free_children, baby } = offer.pax_count
            data.adultCount.add(adult)
            data.concepts.add(concept)
            data.cancellationPolicy.add(cancellation_policy.label)
            if (free_children) data.freeChildCount.add(free_children)
            if (baby) data.babyCount.add(baby)
        })
    })

    Object.keys(data).forEach(key => data[key] = [...data[key]])

    return data
}

const createFilter = (filterValues, filterTitle, filterType) => {
    if (filterValues.length > 0) {
        createdFilters.push(createEmptyFilterContent(filterTitle, filterType))
        createdFilters[createdFilters.length - 1].filters = filterValues
            .map((count, index) => createFilterItem(index, count, true))
    }
}

const createEmptyFilterContent = (title, type) => ({title, type, filters: []})

const createFilterItem = (id, name, value) => ({id, name, value})
