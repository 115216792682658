const Notifications = {
    state: {
        notifications: null,
    },

    mutations: {
        setNotifications(state, notifications){
            state.notifications = notifications
        }
    },

    getters: {
        _getNotifications: state => state.notifications,
    },
}

export default Notifications;
