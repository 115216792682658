import helper from '@helpers/ForStore/Bus.js';
const ListPage = {
    state: {
        busListIsSelected: false,
        busDetails: {},
        openedBusID: null,
    },

    mutations: {
        setBusListIsSelected(state, value) {
            state.busListIsSelected = value;
        },

        setSelectedBusDetails(state, busDetail) {
            state.busDetails[busDetail.id] = busDetail
        },

        setOpenedBusID(state, busId) {
            state.openedBusID = busId
            helper.setUrlAddressWithBusId(busId)
        },

        setBusListPageVuexPropsToEmpty(state) {
            state.busListIsSelected = false;
            state.busDetails = {};
            state.openedBusID = null;
        }
    },

    getters: {
        _getBusListIsSelected: state => state.busListIsSelected,

        _getSelectedBusDetails: state => state.busDetails,

        _getSelectedBusDetailsWithBusId: state => (busId) => state.busDetails[busId] ?? null,

        _getSelectedBusDetailsWithBusOpenedBusID: state  => state.busDetails[state.openedBusID] ?? null,

        _getOpenedBusID: state => state.openedBusID,
    }
}

export default ListPage;
