const SsrHeaders = {
    state: {
        headers: null,
        jsonLd: null
    },

    mutations: {
        setSsrHeaders(state, headers) {
            if (!state.headers) state.headers = {}
            state.headers = { ...state.headers, ...headers }
        },

        addSsrHeader(state, header) {
            if (!state.headers) state.headers = {}
            state.headers = { ...state.headers, ...header }
        },

        addJsonLD(state, jsonLD) {
            if (!state.jsonLd || !Array.isArray(state.jsonLd)) state.jsonLd = []
            state.jsonLd.push(jsonLD)
        },

        setJsonLD(state, jsonLD) {
            state.jsonLd = jsonLD
        }
    },

    getters: {
        _getSsrHeaders: state => state.headers,
        _getJsonLD: state => state.jsonLd
    }
}

export default SsrHeaders
