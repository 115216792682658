import GlobalAxios from "@utils/Api/GlobalAxios.js";
import Helpers from "@helpers/Index.js";

const getBusList = () => {
    return GlobalAxios.Api.post(
        'bus-ticket/availability',
        Helpers.getQueryParams(),
        {
            headers: {
                'Accept': 'application/json'
            }
        }
    )
}

export default getBusList
