import store from "@store/Store.js";
import {addDays, differenceInDays, formatISO9075} from "date-fns";
import {convertObjectDateToStringDateTypeISO8601} from "@helpers/TicketTabs/Global.js";
import helpers from "@helpers/Index.js";

export const changeOtherLocation = (value) => {
    store.commit('setTicketTabsSearchFormWithKey', {key: 'isOtherLocation', value, pageType: 'car'})

    store.commit('setTicketTabsSearchFormLocationValuesWithKey', {
        key: 'landingPlace',
        value: value ?
            {id: null, name: null, city_name: null} :
            store.getters._getCarTabSearchForm.locationValues.departurePlace,
        pageType: 'car'
    })
}

export const changeLocationValues = () => {
    let tempLocationValues = {...store.getters._getCarTabSearchForm.locationValues}
    tempLocationValues.landingPlace = {...store.getters._getCarTabSearchForm.locationValues.departurePlace, type: 'landing'}
    tempLocationValues.departurePlace = {...store.getters._getCarTabSearchForm.locationValues.landingPlace, type: 'departure'}

    store.commit('setTicketTabsSearchFormWithKey', {
        key: 'locationValues',
        value: tempLocationValues,
        pageType: 'car'
    })
}

export const updateTimepickerValue = (selectedTime) => {
    store.commit('setTicketTabsSearchFormWithDoubleKey', {
        parentKey: 'timeValues',
        childKey: selectedTime.type,
        value: {...selectedTime},
        pageType: 'car'
    })
    controlDepartureDateTime()
    controlLandingDateTime()
}

export const changeDatepickerValues = (choseDateObject) => {
    store.commit('setTicketTabsSearchFormWithDoubleKey', {
        parentKey: 'dateValues',
        childKey: `${choseDateObject.type}Date`,
        value: {...choseDateObject},
        pageType: 'car'
    })

    let {departureDate, landingDate} = store.getters._getCarTabSearchForm.dateValues
    departureDate = convertObjectDateToStringDateTypeISO8601(departureDate)
    landingDate = convertObjectDateToStringDateTypeISO8601(landingDate)

    if (choseDateObject.type === 'departure' && differenceInDays(new Date(landingDate), new Date(departureDate)) < 3){
        store.commit('setTicketTabsSearchFormWithDoubleKey', {
            parentKey: 'dateValues',
            childKey: 'landingDate',
            value: helpers.convertStringDateToObjectDate(addDays(new Date(departureDate), 3), 'landing'),
            pageType: 'car'
        })
    }
    controlDepartureDateTime()
    controlLandingDateTime()
}

const controlDepartureDateTime = () => {
    timepickerPropsIsDisablePropChange('pickup', false)
    const departureDate = convertObjectDateToStringDateTypeISO8601(store.getters._getCarTabSearchForm.dateValues.departureDate)

    if (differenceInDays(new Date(departureDate), new Date().setHours(0,0,0,0)) === 0){
        let getNowTimeInMinutes = convertStringTimeToIntTime(formatISO9075(new Date(), { representation: 'time' }).substring(0,5))
        const tempPickupTime = store.getters._getCarTabSearchForm.timeValues.pickup
        if (tempPickupTime.valueTypeNumber < getNowTimeInMinutes){
            tempPickupTime.valueTypeNumber = getNowTimeInMinutes + (30 - (getNowTimeInMinutes % 30))
            tempPickupTime.value = convertIntTimeToStringTime(tempPickupTime.valueTypeNumber)

            store.commit('setTicketTabsSearchFormWithDoubleKey', {
                parentKey: 'timeValues',
                childKey: 'pickup',
                value: {...tempPickupTime},
                pageType: 'car'
            })
        }

        timepickerPropsIsDisablePropChangeWithTimeAndTimeType('pickup', getNowTimeInMinutes)
    }
}

const controlLandingDateTime = () => {
    timepickerPropsIsDisablePropChange('delivery', false)
    let {departureDate, landingDate} = store.getters._getCarTabSearchForm.dateValues
    departureDate = convertObjectDateToStringDateTypeISO8601(departureDate)
    landingDate = convertObjectDateToStringDateTypeISO8601(landingDate)

    if (differenceInDays(new Date(landingDate), new Date(departureDate)) === 0){
        const {delivery, pickup} = store.getters._getCarTabSearchForm.timeValues
        if (delivery.valueTypeNumber < pickup.valueTypeNumber){
            delivery.valueTypeNumber = pickup.valueTypeNumber + 30
            delivery.value = convertIntTimeToStringTime(delivery.valueTypeNumber)

            store.commit('setTicketTabsSearchFormWithDoubleKey', {
                parentKey: 'timeValues',
                childKey: 'delivery',
                value: {...delivery},
                pageType: 'car'
            })
        }

        timepickerPropsIsDisablePropChangeWithTimeAndTimeType('delivery', pickup.valueTypeNumber + 30)
    }
}

const timepickerPropsIsDisablePropChange = (type, value) => {
    const timepickerProps = store.getters._getCarTicketTabsTimePickerProps
    timepickerProps[type].forEach(time => {
        time.isDisable = value
    })
    store.commit('setCarTicketTabsTimePickerProps', timepickerProps)
}

const timepickerPropsIsDisablePropChangeWithTimeAndTimeType = (type, targetTime) => {
    const allTimepickerProps = store.getters._getCarTicketTabsTimePickerProps
    allTimepickerProps[type].forEach(time => {
        if (time.valueTypeNumber < targetTime) time.isDisable = true
    })
    store.commit('setCarTicketTabsTimePickerProps', allTimepickerProps)
}

const convertStringTimeToIntTime = (stringTime) => {
    const [hours, minutes] = stringTime.split(":").map(Number)
    return (hours * 60) + minutes
}

const convertIntTimeToStringTime = (intTime) => {
    const hours = Math.floor(intTime / 60).toString().padStart(2, '0')
    const minutes = (intTime % 60).toString().padStart(2, '0')
    return `${hours}:${minutes}`
}
