import helpers from "@helpers/Index.js";
import {addDays, differenceInDays} from "date-fns";
import UserBeforeSearch from "@helpers/LocalStorage/UserBeforeSearch.js";
import store from "@store/Store.js";

const getDateWithAmountAndType = (amount = 1, type = 'departure') => {
    return helpers.convertStringDateToObjectDate(addDays(new Date(), amount), type)
}

const emptyFlightTicketFormValues = (type) => {
    switch (type) {
        case 'flight':
            return {
                locationValues: {
                    departurePlace: {
                        city_code:"",
                        city_name:"",
                        code:"",
                        country_code:"",
                        country_name:"",
                        is_city:false,
                        name:"",
                    },
                    landingPlace: {
                        city_code:"",
                        city_name:"",
                        code:"",
                        country_code:"",
                        country_name:"",
                        is_city:false,
                        name:"",
                    },
                },
                dateValues: {
                    departureDate: getDateWithAmountAndType(),
                    landingDate: {
                        dayNumber: null,
                        dayLong: null,
                        dayShort: null,
                        monthNumber: null,
                        monthLong: null,
                        monthShort: null,
                        year: null,
                        type: 'landing',
                    }
                },
                passengerValues: {
                    passengers: {
                        adult: {
                            name: 'Yetişkin',
                            count: 1,
                            type: 'adult',
                            description: '',
                            decrease: false,
                            increase: true,
                        },
                        child: {
                            name: 'Çocuk',
                            count: 0,
                            type: 'child',
                            description: '(2 - 12 Yaş Arası)',
                            decrease: false,
                            increase: true,
                        },
                        baby: {
                            name: 'Bebek',
                            count: 0,
                            type: 'baby',
                            description: '(0 - 2 Yaş Arası)',
                            decrease: false,
                            increase: true,
                        },
                        old: {
                            name: '65 yaş üstü',
                            count: 0,
                            type: 'old',
                            description: '',
                            decrease: false,
                            increase: true,
                        },
                        student: {
                            name: 'Öğrenci',
                            count: 0,
                            type: 'student',
                            description: '(12 - 24 Yaş Arası)',
                            decrease: false,
                            increase: true,
                        },
                    },
                    flightTypes:[
                        {
                            name: 'Hepsi',
                            isSelect: true,
                        },
                        {
                            name: 'Ekonomi',
                            isSelect: false,
                        },
                        {
                            name: 'Premium Economy',
                            isSelect: false,
                        },
                        {
                            name: 'Business',
                            isSelect: false,
                        },
                    ]
                },
                isNonStop: false,
                isOneDirection: true
            }
        case 'car':
            return {
                locationValues: {
                    departurePlace: {
                        id: null,
                        name: null,
                        city_name: null,
                    },
                    landingPlace: {
                        id: null,
                        name: null,
                        city_name: null,
                    },
                },
                dateValues: {
                    departureDate: getDateWithAmountAndType(),
                    landingDate: getDateWithAmountAndType(4, 'landing')
                },
                isOtherLocation: false,
                timeValues: {
                    delivery: {
                        value: '10:00',
                        type: 'delivery',
                        valueTypeNumber: 600,
                        isDisable: false,
                    },
                    pickup: {
                        value: '10:00',
                        type: 'pickup',
                        valueTypeNumber: 600,
                        isDisable: false,
                    },
                },
            }
        case 'hotel':
            return {
                guestSize:[
                    {
                        adultValue: 2,
                        childrenAges: [],
                    },
                ],
                locationValues: {
                    departurePlace: {
                        location_id: '',
                        name: '',
                        type: '',
                    }
                },
                dateValues: {
                    departureDate: getDateWithAmountAndType(),
                    landingDate: getDateWithAmountAndType(4, 'landing')
                }
            }
        case 'bus':
            return {
                locationValues: {
                    departurePlace: {
                        id: null,
                        name: null,
                        description: null,
                        country_code: null,
                        region: null,
                        is_center: null,
                        type: 'departure'
                    },
                    landingPlace: {
                        id: null,
                        name: null,
                        description: null,
                        country_code: null,
                        region: null,
                        is_center: null,
                        type: 'landing'
                    },
                },
                dateValues: {
                    departureDate: getDateWithAmountAndType()
                }
            }
        default: return {}
    }
}

export const createTicketFormValues = (pageType, isHotelSearchRooms = false) => {
    store.commit(
        'setTicketTabsSearchForm',
        {
            searchForm: isHotelSearchRooms
                ? {...setSearchFormForHotelRoomsSearch(emptyFlightTicketFormValues('hotel'))}
                : {...getSearchForm(pageType)},
            pageType
        }
    )
}

const checkBeforeSearchAndGetSearchForm = (pageType) => {
    try {
        const allBeforeSearch = UserBeforeSearch.getAllBeforeSearchFromStorage()
        const targetBeforeSearch = allBeforeSearch ? allBeforeSearch[pageType] : []
        const isOldPassengersValue =  targetBeforeSearch.length ? Array.isArray(targetBeforeSearch[0]?.passengerValues?.passengers) : false
        if (isOldPassengersValue){
            UserBeforeSearch.clearBeforeSearchBySearchType('flight')
            return []
        }

        return targetBeforeSearch
    }
    catch (e) {
        return []
    }
}

const getSearchForm = (pageType) => {
    if (pageType === 'car') setTimesForCar()

    const emptyFormValues = emptyFlightTicketFormValues(pageType)

    if (store.getters._getPageIsReadyForSSR){
        let {isAutoSave, isListPage} = store.getters._getPageProps || {}
        if (typeof window !== "undefined" && typeof isAutoSave === "undefined"){
            isAutoSave = !window.frameElement
        }

        const isCanBeSaved = isAutoSave || (isListPage && !isAutoSave)
        const targetBeforeSearch = checkBeforeSearchAndGetSearchForm(pageType);
        let searchForm = emptyFormValues
        if (isCanBeSaved && targetBeforeSearch?.length){
            searchForm = checkBeforeSearchDates(targetBeforeSearch[0], pageType)
        }
        return checkAndSetSearchFormForDestinationsPages(searchForm, pageType)
    }

    return emptyFormValues
}

const checkAndSetSearchFormForDestinationsPages = (searchForm, pageType) => {
    const pagesSearchForm = store.getters._getPageProps?.pagesSearchForm

    if (!pagesSearchForm) return searchForm

    const {origin, destination, pickup_location, drop_off_location, location} = pagesSearchForm
    const {departurePlace, landingPlace} = emptyFlightTicketFormValues(pageType).locationValues

    const isHotel = pageType === 'hotel' && location,
            isBus = pageType === 'bus' && (origin?.name || destination?.name),
            isFlight = pageType === 'flight' && (origin?.code || destination?.code),
            isCar = pageType === 'car' && (pickup_location || drop_off_location);

    if (isHotel){
        searchForm.locationValues.departurePlace = location
    }
    else if(isBus){
        searchForm.locationValues.departurePlace = origin?.name ? origin : departurePlace
        searchForm.locationValues.landingPlace = destination?.name ? destination : landingPlace
    }
    else if(isFlight){
        searchForm.locationValues.departurePlace = origin?.code ? origin : departurePlace
        searchForm.locationValues.landingPlace = destination?.code ? destination : landingPlace
    }
    else if(isCar){
        searchForm.locationValues.departurePlace = pickup_location || departurePlace
        searchForm.locationValues.landingPlace = drop_off_location || landingPlace

        if (drop_off_location) searchForm.isOtherLocation = true
    }

    return searchForm
}

const setSearchFormForHotelRoomsSearch = (searchForm) => {
    const {start_date, end_date, rooms} = store.getters._getPageProps.searchForm
    searchForm.dateValues = {
        departureDate: helpers.convertStringDateToObjectDate(start_date, 'departure'),
        landingDate: helpers.convertStringDateToObjectDate(end_date, 'landing')
    }
    searchForm.guestSize = Object.values(rooms).map(guest => ({
        adultValue: Number(guest.adults),
        childrenAges: [...(guest.children_ages || [])]
    }))
    return searchForm
}

const setTimesForCar = () => {
    const tempTimeArray = Array.from({ length: 48 }, (_, i) => ({
        "value": `${String(Math.floor(i / 2)).padStart(2, '0')}:${i % 2 === 0 ? "00" : "30"}`,
        "isDisable": false,
        "valueTypeNumber": i * 30
    }))
    store.commit('setCarTicketTabsTimePickerProps', {
        delivery: tempTimeArray,
        pickup: tempTimeArray,
    })
}

export const checkBeforeSearchDates = (beforeSearch, pageType = 'flight') => {
    let departureDate = convertObjectDateToStringDateTypeISO8601(beforeSearch.dateValues.departureDate)
    if (
        differenceInDays(new Date(departureDate), new Date()) < 0
        || (pageType === 'car' && differenceInDays(new Date(departureDate), new Date()) === 0)
    ){
        beforeSearch.dateValues.departureDate = helpers.convertStringDateToObjectDate(
            addDays(new Date(), pageType === 'car' ? 1 : 0),
            'departure'
        )

        if (pageType !== 'bus') beforeSearch = checkReturnDate(beforeSearch, pageType)
    }
    return beforeSearch
}

const checkReturnDate = (beforeSearch, pageType) => {
    if (!beforeSearch.isOneDirection){
        let departureDate = convertObjectDateToStringDateTypeISO8601(beforeSearch.dateValues.departureDate)
        let landingDate = convertObjectDateToStringDateTypeISO8601(beforeSearch.dateValues.landingDate)

        if (differenceInDays(new Date(landingDate), new Date(departureDate)) < 1){
            beforeSearch.dateValues.landingDate = pageType !== 'flight'
                ? helpers.convertStringDateToObjectDate(addDays(new Date(departureDate), 2), 'landing')
                : { ...beforeSearch.dateValues.departureDate, type: 'landing'}
        }
    }

    return beforeSearch
}

export const convertObjectDateToStringDateTypeISO8601 = (objectDate) => {
    return objectDate ? `${objectDate.year}/${objectDate.monthNumber}/${objectDate.dayNumber}` : null
}
