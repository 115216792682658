import store from "@store/Store.js";

const flightPassengerTypeArray = {
    ADT: {
        tr: 'Yetişkin',
        en: 'adult',
    },
    CHD: {
        tr: 'Çocuk',
        en: 'child',
    },
    INF: {
        tr: 'Bebek',
        en: 'baby',
    },
    STU: {
        tr: 'Öğrenci',
        en: 'student',
    },
    YCD: {
        tr: '65 yaş üstü',
        en: 'old',
    },
}

const createFlightPassenger = (passenger = null) => {
    const returnedValue = {
        nameProps: {
            placeholder: 'Ad',
            placeholderType: 'top',
            errorMessage: '',
            result: passenger ?? null,
            value: passenger?.nameProps ?? '',
        },
        lastNameProps: {
            placeholder: 'Soyad',
            placeholderType: 'top',
            errorMessage: '',
            result: passenger ?? null,
            value: passenger?.lastNameProps ?? '',
        },
        birthDateProps: {
            placeholder: 'Doğum Tarihi',
            placeholderType: 'top',
            errorMessage: '',
            result: passenger ?? null,
            value: passenger?.birthDateProps ?? '',
        },
        identityNumberProps: {
            placeholder: 'TC Kimlik No',
            isNotTurkishPeople: passenger ? !(!!passenger.identityNumberProps) : false,
            placeholderType: 'top',
            errorMessage: '',
            result: passenger ?? null,
            value: passenger?.identityNumberProps ?? '',
        },
        gender: {
            errorMessage: '',
            result: passenger ?? null,
            value: passenger?.gender ?? '',
        },
    }

    if (!passenger){
        returnedValue.saveProps = {
            isSave: {
                value: true
            },
        }
    }

    return returnedValue
}

const setReservationPassengerPrice = (passengerArray, statePrice = 0) => {
    return Object.values(passengerArray || {})
        .reduce(
            (acc, passenger) => {
                return acc + (passenger.baseFare + passenger.tax + passenger.serviceFee) * passenger.quantity
            },
            statePrice
        )
}

const setPaymentPassengerPrice = (passengerArray = {}) => {
    return passengerArray.reduce((totalPrice, passenger) => {
        return totalPrice + passenger.flight_tickets.reduce((passengerTotalPrice, passengerPrice) => {
            return passengerTotalPrice + passengerPrice.price + passengerPrice.tax + passengerPrice.service_fee;
        }, 0);
    }, 0)
}

const setReservationPagePassengersCount = (passengerArray) => {
    return (passengerArray && Object.values(passengerArray).reduce((count, passenger) => count + passenger.quantity, 0)) || 0
}

const setReservationPagePassengersCountWithoutBabyCount = (passengerArray) => {
    const passengersCount = setReservationPagePassengersCount(passengerArray)
    const babyCount = passengerArray.INF ? passengerArray.INF.quantity : 0
    return passengersCount - babyCount
}

const setPaymentPagePassengersCount = (passengerArray) => {
    return passengerArray.length
}

const handleFlightPassengersFormValuesForPaymentPage = (passengers) => {
    const passengersHandle = () => {
        const returnedValue = {
            ADT: [],
            CHD: [],
            INF: [],
            STU: [],
            YCD: [],
        }

        passengers.forEach(passenger => {
            const {name, surname, birthdate, citizen_id, gender, type} = passenger;
            returnedValue[type].push({
                nameProps: name,
                lastNameProps: surname,
                birthDateProps: birthdate.split("-").reverse().join("-"),
                identityNumberProps: citizen_id,
                gender,
            })
        })

        return returnedValue
    }

    const returnedPassengerList = []

    Object.entries(passengersHandle()).forEach(([key, value])=>{
        for (let index = 0; index < value.length; index++){
            returnedPassengerList.push(
                {
                    group: key,
                    type: flightPassengerTypeArray[key].en,
                    title: ((value.length > 1) ? `${index + 1}. ` : '') + flightPassengerTypeArray[key].tr,
                    info: createFlightPassenger(value[index])
                }
            )
        }
    })

    return returnedPassengerList
}

const handleFlightPassengersFormValuesForReservationPage = (passengers) => {
    const tempPassengerArray = []
    for (const [key, value] of Object.entries(passengers)) {
        for (let index = 0; index < value.quantity; index++) {
            const group = key;
            const type = flightPassengerTypeArray[key].en;
            const title = (value.quantity > 1) ? `${index + 1}. ${flightPassengerTypeArray[key].tr}` : flightPassengerTypeArray[key].tr;
            const info = createFlightPassenger();

            tempPassengerArray.push({ group, type, title, info });
        }
    }
    return tempPassengerArray
}

const createPassengersSendData = (passenger, isAuthenticated) => {
    const tempData = {
        type: passenger.group,
        gender: passenger.info.gender.value,
        name: passenger.info.nameProps.value,
        surname: passenger.info.lastNameProps.value,
        is_foreign: passenger.info.identityNumberProps.isNotTurkishPeople ? 1 : 0,
    }

    if (tempData.is_foreign === 0) tempData.citizen_id = passenger.info.identityNumberProps.value

    const isAgent = store.getters._getAgentCode
    if (isAuthenticated && !isAgent){
        const saveProps = passenger.info.saveProps
        if (saveProps.isSave && saveProps.isSave.value){
            tempData.add_to_passenger_list = saveProps.isSave.value
        }
        else if(saveProps.isUpdate && saveProps.isUpdate.value){
            tempData.update_from_passenger_list = saveProps.isUpdate.value
            tempData.ref_id = saveProps.isUpdate.ref_id
        }
    }

    tempData.birthdate = passenger.info.birthDateProps.value
        .split("-")
        .reverse()
        .join("-")

    return tempData
}

const getFlightSelectedProductsPrice = (products, passengerCount) => {
    return Object.values(products || {})
        .filter(product => product.default_selected)
        .reduce((totalPrice, product) => totalPrice + (product.price * passengerCount), 0)
}

export default {
    setReservationPassengerPrice,
    setPaymentPassengerPrice,
    setReservationPagePassengersCount,
    setReservationPagePassengersCountWithoutBabyCount,
    setPaymentPagePassengersCount,
    handleFlightPassengersFormValuesForPaymentPage,
    handleFlightPassengersFormValuesForReservationPage,
    createPassengersSendData,
    getFlightSelectedProductsPrice,
}
