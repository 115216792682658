import helpers from '@helpers/Index.js';

const QuickReview = {
    state: {
        targetHotelValues: null,
        targetRoomValues: null,
    },

    mutations: {
        setTargetHotelValues(state, payload) {
            helpers.changeBodyOverflowY(!!payload)
            state.targetHotelValues = payload;
        },

        setTargetRoomValues(state, payload) {
            helpers.changeBodyOverflowY(!!payload)
            state.targetRoomValues = payload;
        }
    },

    getters: {
        _getTargetHotelValues: state => state.targetHotelValues,

        _getTargetRoomValues: state => state.targetRoomValues,
    },
}

export default QuickReview;
