const ApiErrors = {
    state: {
        apiErrorValues: [],
    },

    mutations: {
        setApiErrorValue(state, value) {
            state.apiErrorValues = value
        },

        addApiErrorValue(state, error) {
            state.apiErrorValues.push(error)
        },
    },

    getters: {
        _getApiError: state => state.apiErrorValues,
    },
}

export default ApiErrors
