import handleRoomFilter from "@utils/Filters/Hotel/Rooms/Filter.js";
import {handleHotelListFiltersAndShortingOption} from "@helpers/ListPages/Hotel.js";
import {
    applyFilters,
    filter,
    allFiltersToDefault,
} from "@helpers/ForStore/Filters.js";

const Filters = {
    state: {
        defaultFilters: {
            flight: null,

            car: null,

            bus: null,
        },

        listPageFilters: null,

        isFiltersProcessing: false,

        //Hotel Filters
        hotelRoomFilters: null,
        hotelListFilters: [],
    },

    mutations: {
        setFilterOptions(state, option){
            state.defaultFilters[option.type] = JSON.parse(JSON.stringify(option.value))
            state.listPageFilters = option.value
        },

        setFiltersProcessingStatus(state, status){
            state.isFiltersProcessing = status
        },

        setListPageFiltersToDefault(state){
            if (state.defaultFilters[state.pageType]){
                state.listPageFilters = JSON.parse(JSON.stringify(state.defaultFilters[state.pageType]))
                state.isFiltersProcessing = true
                allFiltersToDefault()
                setTimeout(()=>{
                    state.isFiltersProcessing = false
                }, 400)
            }
        },

        setListPageFiltersSelectOnlyOneFilter(state, {filterGroupId, filterId, willMobileBeControlled = true}){
            state.listPageFilters[filterGroupId].filters.forEach((filter) => {
                filter.value = filter.id === filterId
            })
            applyFilters(willMobileBeControlled)
        },

        setListPageFiltersToggleOneCheckboxValue(state, {filterGroupId, filterId}){
            state.listPageFilters[filterGroupId].filters[filterId].value = !state.listPageFilters[filterGroupId].filters[filterId].value
            applyFilters()
        },

        setListPageFiltersRangeValue(state, {filterGroupId, filterId, value}){
            state.listPageFilters[filterGroupId].filters[filterId].value = value
            applyFilters()
        },

        setListPageFiltersSetAllGroupValues(state, {filterGroupId, value}){
            state.listPageFilters[filterGroupId].filters.forEach(filter=>{
                filter.value = value
            })
            applyFilters()
        },

        setListPageFiltersMinMaxValues(state, {filterGroupId, value}){
            state.listPageFilters[filterGroupId].filters[0].min.value = value.min || null
            state.listPageFilters[filterGroupId].filters[0].max.value = value.max || null
            applyFilters()
        },

        triggerFilterForMobile(state){
            state.isFiltersProcessing = true
            filter(state.pageType, state.pageProps.isInternationalRoundTrip)
            setTimeout(()=>{
                state.isFiltersProcessing = false
            }, 400)
        },

        FiltersVuexToDefault(state){
            state.defaultFilters = {
                flight: null,
                car: null,
                bus: null,
            }
            state.listPageFilters = null
            state.isFiltersProcessing = false
        },

        //Hotel Filters
        setHotelListFilters(state, filters) {
            state.hotelListFilters = filters
        },

        clearHotelListFilters(state) {
            state.hotelListFilters.forEach(filter => {
                if (filter.type === 'min-max') {
                    filter.value = null
                }
                else{
                    filter.variables.forEach(filterItem => {
                        filterItem.selected = false
                    })
                }
            })
            handleHotelListFiltersAndShortingOption()
        },

        changeCheckboxValueInHotelListFilter(state, filterValues) {
            state.hotelListFilters.forEach(filter => {
                if (filter.key === filterValues.key) {
                    filter.variables.map(filterItem => {
                        if (JSON.stringify(filterItem) === JSON.stringify(filterValues.value)){
                            filterItem.selected = !filterItem.selected
                        }
                    })
                }
            })
            if (filterValues.isHandleFilters) handleHotelListFiltersAndShortingOption()
        },

        setHotelMinMaxValuesFilter(state, {min, max, isHandleFilters}) {
            state.hotelListFilters.forEach(filter => {
                if (filter.type === 'min-max') {
                    filter.value = {
                        min: !!min ? min : null,
                        max: !!max ? max : null
                    }
                }
            })
            if (isHandleFilters) handleHotelListFiltersAndShortingOption()
        },

        setHotelRoomsFilters(state, filters) {
            state.hotelRoomFilters = filters
        },

        toggleRoomsFilter(state, {filterIndex, filterItemIndex}) {
            state.hotelRoomFilters[filterIndex].filters[filterItemIndex].value = !state.hotelRoomFilters[filterIndex].filters[filterItemIndex].value
            handleRoomFilter()
        },

        cleanRoomsFilters(state) {
            state.hotelRoomFilters.forEach(filter => {
                filter.filters.forEach(filterItem => {
                    filterItem.value = true
                })
            })
            handleRoomFilter()
        },
    },

    getters: {
        _getFiltersProcessingStatus: state => state.isFiltersProcessing,

        _getListPageFilters: state => state.listPageFilters,

        _getListPageFiltersByFilterGroupId: state => (filterGroupId) => state.listPageFilters[filterGroupId],

        _getAllChoseFilterByFilterGroupId: state => (filterGroupId) => {
            const hasTrue = state.listPageFilters[filterGroupId].filters.some(filter => filter.value)
            const hasFalse = state.listPageFilters[filterGroupId].filters.some(filter => !filter.value)
            return hasFalse && hasTrue
                ? null
                : hasFalse !== state.listPageFilters[filterGroupId].filters.length
        },

        //Hotel Filters
        _getHotelRoomsFilters: state => state.hotelRoomFilters,

        _getHotelListFilters: state => state.hotelListFilters,

        _getHotelListSelectedFilters: state => {
            const selectedFilters = []
            state.hotelListFilters.forEach(filter => {
                if (filter.type === 'min-max') {
                    if (filter.value?.max || filter.value?.min) selectedFilters.push(filter)
                }
                else{
                    const variables = filter.variables.filter(item => item.selected)
                    if (variables.length){
                        selectedFilters.push({
                            ...filter,
                            variables
                        })
                    }
                }
            })
            return selectedFilters
        }
    },
}

export default Filters
