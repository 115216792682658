import FlightVuexHelpers from '@helpers/ForStore/Flight.js'
import helpers from '@helpers/Index.js'

const FlightPassengers = {
    state: {
        flightPassengersFormValues: null,
        flightPassengersCount: 0,
        flightPassengersCountWithoutBabyCount: 0,
        reservationPageFlightPassengersPrice: 0,
        paymentPageFlightPassengersPrice: 0,
    },

    mutations: {
        setFlightPassengersForReservationPage(state, passengers){
            state.flightPassengersCount = FlightVuexHelpers.setReservationPagePassengersCount(passengers)
            state.flightPassengersCountWithoutBabyCount = FlightVuexHelpers.setReservationPagePassengersCountWithoutBabyCount(passengers)
            state.reservationPageFlightPassengersPrice += FlightVuexHelpers.setReservationPassengerPrice(passengers, state.reservationPageFlightPassengersPrice)
            state.flightPassengersFormValues = FlightVuexHelpers.handleFlightPassengersFormValuesForReservationPage(passengers)
        },

        setFlightPassengersForPaymentPage(state, passengers){
            state.flightPassengersCount = FlightVuexHelpers.setPaymentPagePassengersCount(passengers)
            state.paymentPageFlightPassengersPrice = FlightVuexHelpers.setPaymentPassengerPrice(passengers)
            state.flightPassengersFormValues = FlightVuexHelpers.handleFlightPassengersFormValuesForPaymentPage(passengers)
        },

        setFlightReservationPassengersFormValues(state, passengerFormValues){
            state.flightPassengersFormValues = passengerFormValues
        },

        setFlightReservationPassengersByIdAndType(state, passengerValues){
            state.flightPassengersFormValues[passengerValues.id].info[passengerValues.inputType] = passengerValues.value
            helpers.localStorage
                .reservationPageUserValues
                .setFlightPassengersInLocaleStorageOneByOne(
                    state.flightPassengersFormValues[passengerValues.id].group,
                    passengerValues.id,
                    passengerValues.inputType,
                    passengerValues.value.value
                )
        },

        setFlightReservationPassengersById(state, passengerValues){
            state.flightPassengersFormValues[passengerValues.id].info = passengerValues.value
            helpers.localStorage
                .reservationPageUserValues
                .setFlightPassengersInLocaleStorage()
        },
    },

    getters: {
        _getReservationPageFlightPassengersTotalPrice: state => state.reservationPageFlightPassengersPrice,

        _getPaymentPageFlightPassengersTotalPrice: state => state.paymentPageFlightPassengersPrice,

        _getReservationPageFlightPassengersCount: state => state.flightPassengersCount,

        _getReservationPageFlightPassengersCountWithoutBabyCount: state => state.flightPassengersCountWithoutBabyCount,

        _getFlightPassengersFormValues: state => state.flightPassengersFormValues,

        _getReservationPageFlightPassengersFormValuesForSend: state => (isAuthenticated) => {
            return state.flightPassengersFormValues
                .map(passenger => FlightVuexHelpers.createPassengersSendData(passenger, isAuthenticated))
        },
    }
}

export default FlightPassengers;
