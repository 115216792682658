import GlobalAxios from "@utils/Api/GlobalAxios.js";

const getBusDetail = (sendData) => {
    return GlobalAxios.Api.post(
        '/bus-ticket/detail',
        sendData
    )
}

export default getBusDetail
