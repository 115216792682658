import GlobalAxios from "@utils/Api/GlobalAxios.js";

export const reservation = (data) => {
    return GlobalAxios.Api.post(
        '/hotel/save-customer-and-rooms',
        {
            ...data
        }
    )
}
