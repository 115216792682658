import InternationalFlightFilter from "@utils/Filters/Flight/InternationalRoundTrip/Filter.js";
import DomesticFlightFilter from "@utils/Filters/Flight/Domestic/Filter.js";
import CarFilter from "@utils/Filters/Car/Filter.js";
import BusFilter from "@utils/Filters/Bus/Filter.js";
import store from "@store/Store.js";

export const applyFilters = (willMobileBeControlled = true) => {
    if (willMobileBeControlled ? !store.getters._getIsMobile : true){
        store.commit('setFiltersProcessingStatus', true)
        filter(store.getters._getPageType, store.getters._getPageProps.isInternationalRoundTrip)
        setTimeout(()=>{
            store.commit('setFiltersProcessingStatus', false)
        }, 400)
    }
}

export const filter = (pageType, isInternationalRoundTrip) => {
    switch (pageType) {
        case 'flight':
            isInternationalRoundTrip ?
                InternationalFlightFilter() :
                DomesticFlightFilter()
            break;
        case 'car':
            CarFilter()
            break;
        case 'bus':
            BusFilter()
            break;
    }
}

export const allFiltersToDefault = () => {
    let searchResult = JSON.parse(JSON.stringify(store.getters._getSearchResult))
    const pageType = store.getters._getPageType

    if (pageType === 'flight') {
        searchResult = flightFiltersToDefault(store.getters._getPageProps.isInternationalRoundTrip, searchResult);
    }
    else if (pageType === 'car') {
        searchResult = carFiltersToDefault(searchResult);
    }
    else if (pageType === 'bus') {
        searchResult = busFiltersToDefault(searchResult);
    }

    store.commit(
        'setSearchResult',
        searchResult
    )
}

const flightFiltersToDefault = (isInternationalRoundTrip, flightData) => {
    if (!isInternationalRoundTrip){
        Object.keys(flightData.flightList).forEach(flightType => {
            flightData.flightList[flightType].forEach(flight => {
                flight.isHidden = false
            })
        })
    }
    else{
        flightData.flightList.forEach(flightGroup => {
            flightGroup.isHidden = false
        })
    }

    return flightData
}

const carFiltersToDefault = (carData) => {
    carData.cars.forEach(car => {
        car.isHidden = false
    })

    return carData
}

const busFiltersToDefault = (busData) => {
    busData.journeys.forEach(bus => {
        bus.isHidden = false
    })

    return busData
}
