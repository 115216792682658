const FavoriteList = {
    state: {
        userFavoriteLists: [],
    },

    mutations: {
        addFavoriteList(state,name){
            state.userFavoriteLists.push({
                listName: name,
                search: []
            })
        },

        saveSearchToFavorites(state, props){
            state.userFavoriteLists[props.index].search.push(props.value)
        },

        deleteSearchInFavoriteList(state,props){
            state.userFavoriteLists[props.listIndex].search.splice(props.searchIndex,1)
        },
    },

    getters: {
        _getUserFavoriteLists: state => state.userFavoriteLists,
    },
}

export default FavoriteList
