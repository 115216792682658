

const MapModal = {
    state: {
        mapModalValues: {}
    },

    mutations: {
        setMapModalValues(state, payload) {
            state.mapModalValues = payload;
        },
    },

    getters: {
        _getMapModalValues: state => state.mapModalValues,
    },
}

export default MapModal;
