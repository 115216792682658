import QuickReview from "@store/Modules/Hotel/QuickReview.js";
import RoomAndHotelDetails from "@store/Modules/Hotel/RoomAndHotelDetails.js";
import ReservationGuests from "@store/Modules/Hotel/Guests.js";

const Hotel = {
    state: {

    },

    mutations: {

    },

    getters: {

    },

    modules: {
        QuickReview,
        RoomAndHotelDetails,
        ReservationGuests,
    }
}

export default Hotel;
