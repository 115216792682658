import { createStore } from "vuex"
import SMPoint from "@store/Modules/SMPoint.js";
import ContactInformation from "@store/Modules/ContactInformation.js";
import BillingForm from "@store/Modules/Billing.js";
import Flight from "@store/Modules/Flight/Index.js";
import Car from "@store/Modules/RentACar/Index.js";
import PaymentForm from "@store/Modules/Payment.js"
import Bus from "@store/Modules/Bus/Index.js";
import Auth from "@store/Modules/Auth.js";
import User from "@store/Modules/User/Index.js";
import ListPagesVuex from "@store/Modules/List/Index.js";
import Filters from "@store/Modules/Filters.js";
import HeaderVuex from "@store/Modules/Header.js";
import TicketTabs from "@store/Modules/TicketTabs.js";
import MiniInformationPopup from "@store/Modules/MiniInformationPopup.js";
import ApiErrors from "@store/Modules/ApiErrors.js";
import Hotel from "@store/Modules/Hotel/Index.js";
import MapModal from "@store/Modules/MapModal.js";
import SsrHeaders from "@store/Modules/SsrHeaders.js";
import SiteSettings from "@store/Modules/SiteSettings.js";
import Contracts from "@store/Modules/Contracts.js";

const store = createStore({
    state: {
        ...Filters.state,

        pageProps: null,

        isMobile: true,

        pageType: null,

        tabsTopTextContent: {
            car: {
                mobile: true,
                desktop: true,
                title: 'En uygun araç kiralama seçenekleri',
                content: null
            },
            bus: {
                mobile: true,
                desktop: true,
                title: 'Ucuz Otobüs Bileti Bulun',
                content: null
            },
            flight: {
                mobile: true,
                desktop: true,
                title: 'En Ucuz Uçak Biletlerini Bulun',
                content: null
            },
            hotel: {
                mobile: true,
                desktop: true,
                title: 'En iyi tatil ve şehir otellerini \n en uygun fiyatlarla bulun!',
                content: null
            },
            dashboard: {
                mobile: true,
                desktop: true,
                title: 'Türkiye\'nin en büyük online seyahat platformu',
                content: 'Otel, uçak, araç kiralama ve otobüs bileti rezervasyonu yapın'
            }
        },

        mobileHeaderZIndex: 9999,

        pageIsReadyForSSR: false,

        agent_code: null,

        isDevMode: false,

        targetActivityId: null,
    },

    mutations:{
        ...Filters.mutations,

        setIsMobile(state, value){
            state.isMobile = value
        },

        setDevMode(state, value){
            state.isDevMode = value
        },

        setPageType(state, type) {
            state.pageType = type
        },

        setPageProps(state, pageProps) {
            state.pageProps = pageProps
        },

        setMobileHeaderZIndex(state, zIndex){
            state.mobileHeaderZIndex = zIndex
        },

        setPageIsReadyForSSR(state, value){
            state.pageIsReadyForSSR = value
        },

        setAgentCode(state, code){
            state.agent_code = code
        },

        setTargetActivityId(state, id){
            state.targetActivityId = id
        }
    },

    getters: {
        ...Filters.getters,

        _getPageType: state => state.pageType,

        _getIsMobile: state => state.isMobile,

        _getPageProps: state => state.pageProps,

        _getTabText: state => state.tabsTopTextContent,

        _getMobileHeaderZIndex: state => state.mobileHeaderZIndex,

        _getPageIsReadyForSSR: state => state.pageIsReadyForSSR,

        _getAgentCode: state => state.agent_code,

        _getDevMode: state => state.isDevMode,

        _getTargetActivityId: state => state.targetActivityId,
    },

    modules: {
        Flight,
        Car,
        MapModal,
        Bus,
        Hotel,
        BillingForm,
        ContactInformation,
        SMPoint,
        PaymentForm,
        Auth,
        User,
        ListPagesVuex,
        HeaderVuex,
        TicketTabs,
        MiniInformationPopup,
        ApiErrors,
        SsrHeaders,
        SiteSettings,
        Contracts,
    }
})

export default store;
