import helpers from "@helpers/Index.js";

const SiteSettings = {
    state: {
        callCenterNumber: '850 335 00 00',

        socialMedia: [
            {
                name: 'facebook',
                url: 'https://www.facebook.com/sorgulamaxcom/',
            },
            {
                name: 'twitter',
                url: 'https://x.com/sorgulamax_',
            },
            {
                name: 'instagram',
                url: 'https://www.instagram.com/sorgulamax/',
            }
        ],

        appLinks: {
            appStore: '',
            playStore: '',
            //playStore: {
            //    url: 'https://srgl.mx/app',
            //    description: '',
            //    name: '',
            //    applicationCategory: '',
            //    datePublished: '',
            //    author: {
            //        "@type": "Person",
            //        "name": "OLIMTUR LTD. STI.",
            //        "url": "https://srgl.mx/app"
            //    },
            //    "aggregateRating": {
            //        "@type": "AggregateRating",
            //        "ratingValue": "4.519999980926514",
            //        "ratingCount": "897",
            //        "worstRating": 1,
            //        "bestRating" : 5,
            //    },
            //    image: '',
            //},
            appGallery: '',
        },

        companyInfo: {
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+908503350000",
                "contactType": "customer service",
                "areaServed": "TR",
                "availableLanguage": "Turkish"
            },
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "Kocaeli",
                "addressCountry": "TR",
                "postalCode": "41740",
                "streetAddress": "Güney, Sancak Cd. No: 2 Kat: 2, 41740 Körfez/Kocaeli"
            },
            "email": "bilgi@sorgulamax.com",
            "faxNumber": "",//TODO Doldur
            "url": 'https://sorgulamax.com',
            "name": "Sorgulamax",
            "alternateName": "Uçak bileti",
            "logo": "https://sorgulamax.com/build/pictures/favicon/android-icon-192x192.png",
            "image": "https://sorgulamax.com/build/pictures/favicon/android-icon-192x192.png",
            "description": "Sorgulamax'ta en avantajlı uçak bileti, otobüs bileti, otel rezervasyonu, araç kiralama fiyatlarını karşılaştırın ve seyahatlerinizde en uygun fiyatı bulun.",
            "vatID": "FR12345678901",
            "iso6523Code": "0199:724500PMK2A2M1SQQ228"
        }
    },

    getters: {
        _getCallCenterNumber: state => state.callCenterNumber,

        _getSiteInfos: state => ({
            callCenterNumber: state.callCenterNumber,
            socialMedia: state.socialMedia,
            appLinks: state.appLinks,
            companyInfo: {
                ...state.companyInfo,
                url: helpers.routeHandler(''),
            },
        }),

        _getAppInfo: state => state.appLinks,
    },
}

export default SiteSettings;
