import GlobalAxios from "@utils/Api/GlobalAxios.js";
import Helpers from "@helpers/Index.js";


const getHotels = (data = null) => {
    if (!data) {
        return GlobalAxios.Api.post(
            'hotel/availability',
            Helpers.getQueryParams(),
            {
                headers: {
                    'Accept': 'application/json'
                }
            }
        )
    }

    return GlobalAxios.Api.post(
        'hotel/availability',
        data,
    )
}

export default getHotels
