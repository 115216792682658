import {handleHotelListFiltersAndShortingOption} from "@helpers/ListPages/Hotel.js";

const ShortingOptions = {
    state: {
        sortingOptions: {},
    },

    mutations: {
        createDefaultSortingOptions(state){
            state.sortingOptions = {
                flight: {
                    selectIndex: 0,
                    options: [
                        {
                            name: 'PRICE_ASC',
                            text: 'En ucuz',
                        },
                        {
                            name: 'FASTEST',
                            text: 'En hızlı',
                        },
                        {
                            name: 'DEPARTURE_ASC',
                            text: 'Kalkış erkenden geçe',
                        },
                        {
                            name: 'DEPARTURE_DESC',
                            text: 'Kalkış geçten erkene',
                        },
                        {
                            name: 'ARRIVAL_ASC',
                            text: 'Varış erkenden geçe',
                        },
                        {
                            name: 'ARRIVAL_DESC',
                            text: 'Varış geçten erkene',
                        },
                    ]
                },

                car: {
                    selectIndex: 0,
                    options: [
                        {
                            name: 'DEFAULT',
                            text: 'Önerilen Sıralama',
                        },
                        {
                            name: 'PRICE_ASC',
                            text: 'Fiyata göre artan',
                        },
                        {
                            name: 'PRICE_DESC',
                            text: 'Fiyata göre azalan',
                        },
                        {
                            name: 'DRIVER_AGE',
                            text: 'Sürücü yaşına göre',
                        },
                        {
                            name: 'LICENSE_YEARS',
                            text: 'Ehliyet süresine göre',
                        },
                    ]
                },

                bus: {
                    selectIndex: 2,
                    options: [
                        {
                            name: 'PRICE_ASC',
                            text: 'Fiyata göre artan',
                        },
                        {
                            name: 'PRICE_DESC',
                            text: 'Fiyata göre azalan',
                        },
                        {
                            name: 'DEPARTURE_ASC',
                            text: 'Kalkış erkenden geçe',
                        },
                        {
                            name: 'DEPARTURE_DESC',
                            text: 'Kalkış geçten erkene',
                        },
                        {
                            name: 'ARRIVAL_ASC',
                            text: 'Varış erkenden geçe',
                        },
                        {
                            name: 'ARRIVAL_DESC',
                            text: 'Varış geçten erkene',
                        },
                    ]
                },

                hotel: [],
            }
        },

        setHotelSortingOptions(state, options){
            state.sortingOptions.hotel = options
        },

        setHotelActiveSortingOption(state, option){
            state.sortingOptions.hotel.forEach(sortingOption => {
                if (sortingOption.label === option.label){
                    sortingOption.selected = !sortingOption.selected
                }
                else {
                    sortingOption.selected = false
                }
            })
            handleHotelListFiltersAndShortingOption()
        },
    },

    getters: {
        _getSortingOptions: state => state.sortingOptions,
    },
}

export default ShortingOptions
