

const createEmptyDriverFormValuesWithPageType = (driverValues) => {
    const returnedDriverFormValues = {
        nameProps: {
            placeholder: 'Ad',
            placeholderType: 'top',
            errorMessage: '',
            result: driverValues ?? null,
            value: driverValues?.name ?? '',
        },
        lastNameProps: {
            placeholder: 'Soyad',
            placeholderType: 'top',
            errorMessage: '',
            result: driverValues ?? null,
            value: driverValues?.surname ?? '',
        },
        birthDateProps: {
            placeholder: 'Doğum Tarihi',
            placeholderType: 'top',
            errorMessage: '',
            result: driverValues ?? null,
            value: driverValues?.birthdate?.split("-").reverse().join("-") ?? '',
        },
        identityNumberProps: {
            placeholder: 'TC Kimlik No',
            isNotTurkishPeople: false,
            placeholderType: 'top',
            errorMessage: '',
            result: driverValues ?? null,
            value: driverValues?.citizen_id ?? '',
        },
    }

    if (!driverValues) {
        returnedDriverFormValues.saveProps = {
            isSave: {
                value: true
            }
        }
    }

    return returnedDriverFormValues
}

const selectedProductsHandleData = (selectedProducts) => {
    return selectedProducts
        .map(
            selectedProduct => ({
                label: selectedProduct.label,
                name: selectedProduct.name,
                price: selectedProduct.price,
                type: selectedProduct.type
            })
        )
}

const getSelectedProducts = (products) => {
    return products.filter(product => product.selected)
}

export default {
    createEmptyDriverFormValuesWithPageType,
    selectedProductsHandleData,
    getSelectedProducts,
}
