
const ListPagesSearchResult = {
    state: {
        listPagesApiResponseData: null,
    },

    mutations: {
        setSearchResult(state, response){
            state.listPagesApiResponseData = response
        },

        changePhotosInHotelInfoById(state, {id = null, photos = null}){
            if (!id || !photos) return;

            if (state.listPagesApiResponseData?.hotels) {
                state.listPagesApiResponseData.hotels.map(hotel => {
                    if (hotel.id === id) {
                        hotel.info.photos = photos
                    }
                    return hotel
                })
            }
        }
    },

    getters: {
        _getSearchResult: state => state.listPagesApiResponseData,

        _getBusJourneyDataWithBusId: state => (busId) => {
            if (state.listPagesApiResponseData && state.listPagesApiResponseData.journeys) {
                return state.listPagesApiResponseData.journeys.find(bus => bus.id === busId)
            }
            return null
        },
    },
}

export default ListPagesSearchResult
