import autocomplete from "@utils/Api/Bus/Modules/Autocomplete.js"
import getBusList from "@utils/Api/Bus/Modules/GetBusList.js"
import getBusDetail from "@utils/Api/Bus/Modules/GetBusDetail.js"
import checkSelectedVehicleAndSeats from "@utils/Api/Bus/Modules/CheckSelectedVehicleAndSeats.js"
import getBusReservationValues from "@utils/Api/Bus/Modules/GetBusReservationValues.js";
import {reservation} from "@utils/Api/Bus/Modules/Reservation.js";

export default {
    autocomplete,
    getBusList,
    getBusDetail,
    checkSelectedVehicleAndSeats,
    getBusReservationValues,
    reservation,
}
