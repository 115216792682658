const PaymentForm = {
    state:{
        paymentFormValues: null,
        installmentValues: null,
        installmentRate: null,
        paymentPrice: 0,
    },

    mutations: {
        setPaymentFormValuesByInputName(state, values){
            state.paymentFormValues[values.type] = values.value
        },

        setPaymentFormValues(state, formValues){
            state.paymentFormValues = formValues
        },

        setInstallmentValues(state, value){
            state.installmentValues = value
        },

        setInstallmentRate(state, rate){
            state.installmentRate = rate
        },

        setPaymentTotalPrice(state, price) {
            state.paymentPrice = price
        },

        createEmptyPaymentFormValues(state){
            state.paymentFormValues = {
                cardNumberProps: {
                    placeholder: "Kart Numarası",
                    placeholderType: 'inner',
                    errorMessage: '',
                    result: null,
                    value: '',
                    autocompleteKey: 'cc-number'
                },
                nameSurname: {
                    placeholder: "Kart Sahibinin İsmi ve Soyismi",
                    placeholderType: 'inner',
                    errorMessage: '',
                    result: null,
                    value: '',
                    autocompleteKey: 'cc-name'
                },
                cardMonth: {
                    errorMessage: '',
                    result: null,
                    value: '',
                    title: 'Ay',
                    autocompleteKey: 'cc-exp-month',
                },
                cardYear: {
                    errorMessage: '',
                    result: null,
                    value: '',
                    title: 'Yıl',
                    autocompleteKey: 'cc-exp-year',
                },
                cardCVV: {
                    placeholder: "CVV",
                    placeholderType: 'inner',
                    errorMessage: '',
                    result: null,
                    value: '',
                    autocompleteKey: 'cc-csc',
                },
                installment_count: 1,
                checkboxInputsValues: [true,true],
                paymentType: 'bank-credit-card'
            }
            state.installmentValues = null
            state.installmentRate = null
            state.paymentPrice = 0
        },
    },

    getters: {
        _getPaymentFormValues: state => state.paymentFormValues,

        _getPaymentType: state => state.paymentFormValues.paymentType,

        _getPaymentTotalPrice: state => state.paymentPrice,

        _getInstallmentValues: state => state.installmentValues,

        _getInstallmentRate: state => state.installmentRate,

        checkPaymentRules: state => state.paymentFormValues.checkboxInputsValues.every(Boolean),

        _getPaymentFormValuesForSend: state => {
            if (state.paymentFormValues.paymentType === 'bank-credit-card'){
                const self = state.paymentFormValues
                const sendData = {
                    payment_method: 'virtualPos',
                    holder_name: self.nameSurname.value.toString(),
                    card_number: self.cardNumberProps.value.toString().replace(/ /g, '-'),
                    expiry_month: self.cardMonth.value.toString(),
                    expiry_year: self.cardYear.value.toString().slice(-2),
                    cvv: self.cardCVV.value.toString(),
                }
                if (self.installment_count > 1){
                    sendData.installment_count = self.installment_count
                }
                return sendData
            }
            else if (state.paymentFormValues.paymentType === 'garanti-pay')
                return {payment_method: "bankPay", payment_method_option: "garantiPay"}
            else return {payment_method: 'transfer'}
        }
    }
}

export default PaymentForm
