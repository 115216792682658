import helpers from '@helpers/Index.js'

const HotelDetails = {
    state: {
        hotelDetails: null,
        hotelRooms: null,
        hotelActivityId: null,
        selectedRooms: [],
        totalPrice: 0,
    },

    mutations: {
        setHotelDetails(state, details) {
            state.hotelDetails = details;
        },

        setHotelPictures(state, photos) {
            try {
                state.hotelDetails.info.photos = photos;
            }
            catch (e) {}
        },

        setHotelRooms(state, rooms) {
            state.hotelRooms = rooms;
        },

        setHotelRoomsAndCalculatePrice(state, rooms) {
            state.hotelRooms = rooms;

            if (rooms.length > 0)
                rooms.forEach(room => {
                    if (Object.values(room.offers).length > 0)
                        state.totalPrice += Object.values(room.offers).reduce(
                            (totalPrice, offer) => totalPrice + (offer.pricing.total * offer.quantity), 0
                        )
                })
        },

        setSelectedRooms(state, rooms) {
            state.selectedRooms = rooms;
        },

        addRoomToSelectedRooms(state, room) {
            if (state.selectedRooms.length === 0) {
                state.selectedRooms.push(room)
            }
            else {
                let index = state.selectedRooms.findIndex(item => item.offer_id === room.offer_id)
                if (index === -1) {
                    state.selectedRooms.push(room)
                }
                else {
                    if (room.roomCount === 0) state.selectedRooms.splice(index, 1)
                    else state.selectedRooms[index] = room
                }
            }
        },

        setHotelActivityId(state, id) {
            state.hotelActivityId = id;
        },

        setEmptyHotelDetailVuex(state) {
            state.hotelDetails = null
            state.hotelRooms = null
            state.hotelActivityId = null
            state.selectedRooms = []
            state.totalPrice = 0
        }
    },

    getters: {
        _getHotelDetails: state => state.hotelDetails,

        _getHotelRooms: state => state.hotelRooms,

        _getSelectedRooms: state => state.selectedRooms,

        _getSelectedRoomCountWithId: state => (id) => {
            return state.selectedRooms.find(room => room.offer_id === id)?.roomCount ?? 0
        },

        _getSelectedRoomsTotalPrice: state => {
            if (!state.selectedRooms?.length) return 0
            const totalPrice = state.selectedRooms.reduce((acc, room) => acc + (room.totalPrice * room.roomCount), 0)
            return helpers.priceFormat(totalPrice || 0).split(',')[0]
        },

        _getHotelActivityId: state => state.hotelActivityId,

        _getHotelTotalPrice: state => state.totalPrice,
    },
}

export default HotelDetails;
