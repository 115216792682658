import BusVuexHelper from "@helpers/ForStore/Bus.js";
import ListPage from "@store/Modules/Bus/ListPage.js";
import helpers from "@helpers/Index.js";

const Bus = {
    state: {
        ...ListPage.state,

        busTotalPrice: 0,
        busSelectedSeats: null,
        busJourneyDetail: null,
        busPackages: null,
        busPassengersFormValues: null,
    },

    mutations: {
        ...ListPage.mutations,

        setBusTotalPrice(state, totalPrice) {
            state.busTotalPrice = totalPrice;
        },

        setBusSelectedSeats(state, seats) {
            state.busSelectedSeats = seats;
        },

        setBusJourneyDetail(state, journeyDetail) {
            state.busJourneyDetail = journeyDetail;
        },

        setBusPackages(state, packages) {
            state.busPackages = packages;
        },

        setBusPassengersFormValues(state, passengerFormValues) {
            state.busPassengersFormValues = passengerFormValues;
        },

        setBusPassengersFormValuesWithSeatNumber(state, payload) {
            state.busPassengersFormValues.forEach((passenger, index) => {
                if (passenger.number === payload.seatNumber) {
                    state.busPassengersFormValues[index].passengerInformation = payload.passengerFormValue
                    helpers.localStorage
                        .reservationPageUserValues
                        .setBusPassengersInLocaleStorageForPassengerGroup(payload.passengerFormValue, index)
                    return false
                }
            })
        },

        setBusPassengersFormValuesWithSeatNumberAndPassengerInputName(state, payload) {
            state.busPassengersFormValues.forEach((passenger, index) => {
                if (passenger.number === payload.seatNumber) {
                    state.busPassengersFormValues[index].passengerInformation[payload.inputType] = payload.inputValues
                    helpers.localStorage
                        .reservationPageUserValues
                        .setBusPassengersInLocaleStorageOneByOne(index, payload.inputType, payload.inputValues.value)
                    return false
                }
            })
        },

        setEmptyBusVuex(state) {
            state.busTotalPrice = 0;
            state.busSelectedSeats = null;
            state.busJourneyDetail = null;
            state.busPackages = null;
        }
    },

    actions: {
        handleBusPassengersValue(context, payload) {
            context.commit('setBusPassengersFormValues', BusVuexHelper.handlePassengers(payload));
            const selectedSeats = payload.isPayment
                ? payload.seats.map(passenger => ({ gender: passenger.gender, number: passenger.bus_ticket.seat_number }))
                : payload.seats
            context.commit('setBusSelectedSeats', selectedSeats)
        }
    },

    getters: {
        ...ListPage.getters,

        _getBusTotalPrice: state => state.busTotalPrice,

        _getBusSelectedSeats: state => state.busSelectedSeats,

        _getBusJourneyDetail: state => state.busJourneyDetail,

        _getBusPackages: state => state.busPackages,

        _getBusPassengersFormValues: state => state.busPassengersFormValues,

        _getBusPassengersFormValuesForSend: state => (isLogin) => BusVuexHelper.getPassengersForSend(state.busPassengersFormValues, isLogin),
    }
}

export default Bus;
