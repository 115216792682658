import store from "@store/Store.js"
import {
    controlFlightByFilter,
    handleFilterOptions,
} from "@utils/Filters/Flight/GlobalFlightFiltersFunctions.js";

let filterOptions = {};

const resetFilter = () => {
    const tempFlightListData = JSON.parse(JSON.stringify(store.getters._getSearchResult))

    Object.values(tempFlightListData.flightList).forEach(flightGroup => {
        flightGroup.forEach(flight => {
            flight.isHidden = false
        })
    })

    return tempFlightListData
}

const FilterDomesticFlights = () => {
    const filters = store.getters._getListPageFilters
    const tempFlightListData = resetFilter()

    if (tempFlightListData.flightList.toString().length > 0) {

        filterOptions = handleFilterOptions(filters)

        Object.entries(tempFlightListData.flightList).forEach(([flightType, value]) => {
            value.forEach(flight => {
                flight = controlFlightByFilter(flight, flightType, filterOptions)
                if (flight.selected){
                    flight.isHidden = false
                }
            })
        })

        store.commit('setSearchResult', tempFlightListData)
    }
}

export const filterWithSelectedDate = () => {
    let returnedValue = false
    const tempFlightListData = JSON.parse(JSON.stringify(store.getters._getSearchResult))

    const searchReturnFlights = (arrivalDateTime) => {
        tempFlightListData.flightList.return.forEach(returnFlight => {
            const isHidden = new Date(arrivalDateTime) >= new Date(returnFlight.departureDatetime)
            if (isHidden){
                returnedValue = true
            }
            returnFlight.isHidden = isHidden
        })
    }

    if (tempFlightListData.flightList.return){
        tempFlightListData.flightList.departure.forEach(departureFlight => {
            if (departureFlight.selected){
                searchReturnFlights(departureFlight.arrivalDatetime)
            }
        })
    }

    store.commit('setSearchResult', tempFlightListData)
    return returnedValue
}

export default FilterDomesticFlights
