import GlobalAxios from "@utils/Api/GlobalAxios.js";

const subscribe = (email) => {
    return GlobalAxios.Api.post(
        '/newsletter/subscription',
        {email}
    )
}

export default {
    subscribe,
}
