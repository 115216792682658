import CarDriver from "@store/Modules/RentACar/Driver.js";
import CarProducts from "@store/Modules/RentACar/Products.js";
import RentACarVuexHelpers from "@helpers/ForStore/Car.js"

const Car = {
    state: {
        ...CarDriver.state,

        ...CarProducts.state,

        showingCarDetails: null,
    },

    mutations: {
        ...CarDriver.mutations,

        ...CarProducts.mutations,

        resetAllCarVuex(state){
            state.reservationPageCarProducts = null
            state.rentACarDriverFormValues = null
            state.rentACarPrice = 0
        },

        setShowingCarDetail(state, detail){
            state.showingCarDetails = detail
        }
    },

    getters: {
        ...CarDriver.getters,

        ...CarProducts.getters,

        _getReservationPageRentACarTotalPrice: state => {
            return RentACarVuexHelpers.getSelectedProducts(state.reservationPageCarProducts || [])
                .reduce(
                    (acc, product) => acc + product.price,
                    state.rentACarPrice
                )
        },

        _getRentACarDriverFormValuesAndSelectedPackagesFormPaymentApi: state => (isLogin, agentCode = null) => {
            const selectedProducts = RentACarVuexHelpers.selectedProductsHandleData(
                RentACarVuexHelpers.getSelectedProducts(state.reservationPageCarProducts || [])
            )
            const {
                nameProps,
                lastNameProps,
                birthDateProps,
                identityNumberProps
            } = state.rentACarDriverFormValues

            const sendData = {
                name: nameProps.value,
                surname: lastNameProps.value,
                birthdate: birthDateProps.value.split("-")[0].length>3 ? birthDateProps.value : birthDateProps.value.split("-").reverse().join("-"),
                citizen_id: identityNumberProps.value,
                products: selectedProducts
            }

            if (isLogin && state.rentACarDriverFormValues.saveProps && !agentCode){
                const saveProps = state.rentACarDriverFormValues.saveProps
                if (saveProps.isSave && saveProps.isSave.value){
                    sendData.add_to_passenger_list = saveProps.isSave.value
                }
                else if(saveProps.isUpdate && saveProps.isUpdate.value){
                    sendData.update_from_passenger_list = saveProps.isUpdate.value
                    sendData.ref_id = saveProps.isUpdate.ref_id
                }
            }

            return sendData
        },

        _getShowingCarDetail: state => state.showingCarDetails
    }
}

export default Car
